import React, { Component } from 'react'
import './App.css'

class TLanding extends Component {
  componentWillMount() {
    this.is_loggined()
  }

  componentDidMount() {}

  is_loggined() {
    var token = window.localStorage.getItem('token')
    if (!token || token === '' || token === 'undefined') {
      window.location = '/login'
    } else {
      window.location = '/account'
    }
  }

  render() {
    return <div></div>
  }
}

export default TLanding
