import React, { Component } from 'react'
import './App.css'

import Toggle from 'react-bootstrap-toggle'
import ReCAPTCHA from 'react-google-recaptcha'

class Test extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      status: false,
      files: [],
      toggleActive: false,
    }

    this.onToggle = this.onToggle.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {}

  componentDidMount() {}

  onChange(value) {
    console.log('Captcha value:', value)
  }

  onToggle() {
    this.setState({ toggleActive: !this.state.toggleActive })
  }

  getUploadParams = ({ meta }) => {
    //return { url: 'https://httpbin.org/post', id:1000 }
    return { url: 'http://localhost/donor_api/donor/photo', id: 1000 }
  }

  handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file)
  }

  handleSubmit = (files) => {
    console.log(files.map((f) => f.meta))
  }

  render() {
    return (
      <div>
        <Toggle
          onClick={this.onToggle}
          on={<h2>Yes</h2>}
          off={<h2>No</h2>}
          size="xs"
          offstyle="danger"
          active={this.state.toggleActive}
        />

        <ReCAPTCHA
          sitekey="6LeyuyoaAAAAAATOMAyHdTziUr-E5mtbQFLnzkcK"
          onChange={this.onChange}
        />
      </div>
    )
  }
}

export default Test
