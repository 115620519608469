import React, { Component } from 'react'

import $ from 'jquery'
import Header from '../header'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from 'react-modal'
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css'
import 'react-switchable/dist/main.css'
import Checkbox from 'rc-checkbox'
import 'rc-checkbox/assets/index.css'
import * as F_UTIL from '../helper'
import axios from 'axios'

import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import img_alert from '../assets/images/error.svg'
import '../App.css'
import _ from 'lodash'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class TDonor_Application extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      load_data: 0,
      imageFiles: null,
      uploading: false,
      refreshImages: false,
      uploadingPrgress: 0,
      frozenCycles: [],
      freshCycles: [],
      steps: [
        {
          id: 0,
          content: 'step1',
          step: 1,
          open: true,
          error: false,
          title: 'Step 1. Contact Information',
        },
        {
          id: 1,
          step: 2,
          content: 'step2',
          open: false,
          error: false,
          title: 'Step 2. Appearance',
        },
        {
          id: 2,
          step: 3,
          content: 'step3',
          open: false,
          error: false,
          title: 'Step 3. General Information',
        },
        {
          id: 3,
          step: 4,
          content: 'step4',
          open: false,
          error: false,
          title: 'Step 4. Education',
        },
        {
          id: 4,
          step: 5,
          content: 'step5',
          open: false,
          error: false,
          title: 'Step 5. Medical',
        },
        {
          id: 5,
          step: 6,
          content: 'step6',
          open: false,
          error: false,
          title: 'Step 6. Genetic',
        },
        {
          id: 6,
          step: 7,
          content: 'step7',
          open: false,
          error: false,
          title: 'Step 7. Traits',
        },
        {
          id: 7,
          step: 8,
          content: 'step8',
          open: false,
          error: false,
          title: 'Step 8. Fresh and Frozen Cycles',
        },
        {
          id: 8,
          step: 9,
          content: 'step9',
          open: false,
          error: false,
          title: 'Step 9. Photos',
        },
        {
          id: 9,
          step: 10,
          content: 'step10',
          open: false,
          error: false,
          title: 'Agreement',
        },
      ],
      cycledate: '',
      thaw_date: '',
      photos: [],
      form_items: [
        {
          id: 0,
          type: 'text',
          name: 'firstname',
          value: '',
        },
        {
          id: 1,
          type: 'text',
          name: 'lastname',
          value: '',
        },
        {
          id: 2,
          type: 'text',
          name: 'hphone',
          value: '',
        },
        {
          id: 3,
          type: 'text',
          name: 'cphone',
          value: '',
        },
        {
          id: 4,
          type: 'text',
          name: 'wphone',
          value: '',
        },
        {
          id: 5,
          type: 'text',
          name: 'email',
          value: '',
        },
        {
          id: 6,
          type: 'text',
          name: 'address',
          value: '',
        },
        {
          id: 7,
          type: 'text',
          name: 'city',
          value: '',
        },
        {
          id: 8,
          type: 'text',
          name: 'zipcode',
          value: '',
        },
        {
          id: 9,
          type: 'text',
          name: 'state',
          value: '',
        },
        {
          id: 10,
          type: 'text',
          name: 'country',
          value: '',
        },

        {
          id: 11,
          type: 'text',
          name: 'nickname',
          value: '',
        },
        {
          id: 12,
          name: 'dob',
          value: '',
          type: 'date',
        },
        {
          id: 13,
          type: 'text',
          name: 'occupation',
          value: '',
        },
        {
          id: 14,
          type: 'text',
          name: 'bodytype',
          value: '',
        },
        {
          id: 15,
          type: 'text',
          name: 'height',
          value: '',
        },
        {
          id: 16,
          type: 'text',
          name: 'weight',
          value: '',
        },
        {
          id: 17,
          type: 'text',
          name: 'eyecolor',
          value: '',
        },
        {
          id: 18,
          type: 'text',
          name: 'haircolor',
          value: '',
        },
        {
          id: 19,
          type: 'text',
          name: 'hairtype',
          value: '',
        },
        {
          id: 20,
          type: 'text',
          name: 'race',
          value: '',
        },
        {
          id: 21,
          type: 'text',
          name: 'complexion',
          value: '',
        },
        {
          id: 22,
          type: 'text',
          name: 'race2',
          value: '',
        },
        {
          id: 23,
          type: 'text',
          name: 'otherfeatures',
          value: '',
        },

        {
          id: 24,
          type: 'text',
          name: 'birthreligion',
          value: '',
        },
        {
          id: 25,
          type: 'text',
          name: 'handside',
          value: '',
        },
        {
          id: 26,
          type: 'text',
          name: 'tattoo',
          value: '',
        },
        {
          id: 27,
          type: 'text',
          name: 'piercing',
          value: '',
        },
        {
          id: 28,
          type: 'text',
          name: 'drive',
          value: '',
        },
        {
          id: 29,
          type: 'text',
          name: 'car',
          value: '',
        },
        {
          id: 30,
          type: 'text',
          name: 'otheragency',
          value: '',
        },
        {
          id: 31,
          type: 'text',
          name: 'maritalstatus',
          value: '',
        },
        {
          id: 32,
          type: 'text',
          name: 'bankruptcy',
          value: '',
        },
        {
          id: 33,
          type: 'text',
          name: 'psychfacility',
          value: '',
        },
        {
          id: 34,
          type: 'text',
          name: 'arrested',
          value: '',
        },
        {
          id: 35,
          type: 'text',
          name: 'legalcases',
          value: '',
        },
        {
          id: 36,
          type: 'text',
          name: 'subabuse',
          value: '',
        },
        {
          id: 37,
          type: 'text',
          name: 'how_find',
          value: '',
        },
        {
          id: 38,
          type: 'text',
          name: 'prompt_register',
          value: '',
        },
        {
          id: 39,
          type: 'text',
          name: 'completedgeneral',
          value: '',
        },

        {
          id: 40,
          type: 'text',
          name: 'jobposition1',
          value: '',
        },
        {
          id: 41,
          type: 'text',
          name: 'jobdates1',
          value: '',
        },
        {
          id: 42,
          type: 'text',
          name: 'jobduties1',
          value: '',
        },
        {
          id: 43,
          type: 'text',
          name: 'jobposition2',
          value: '',
        },
        {
          id: 44,
          type: 'text',
          name: 'jobdates2',
          value: '',
        },
        {
          id: 45,
          type: 'text',
          name: 'jobduties2',
          value: '',
        },
        {
          id: 46,
          type: 'text',
          name: 'jobposition3',
          value: '',
        },
        {
          id: 47,
          type: 'text',
          name: 'jobdates3',
          value: '',
        },
        {
          id: 48,
          type: 'text',
          name: 'jobduties3',
          value: '',
        },
        {
          id: 49,
          type: 'text',
          name: 'educationlevel',
          value: '',
        },
        {
          id: 50,
          type: 'text',
          name: 'major',
          value: '',
        },
        {
          id: 51,
          type: 'text',
          name: 'satscore',
          value: '',
        },
        {
          id: 52,
          type: 'text',
          name: 'actscore',
          value: '',
        },
        {
          id: 53,
          type: 'text',
          name: 'collegegpa',
          value: '',
        },
        {
          id: 54,
          type: 'text',
          name: 'iqscore',
          value: '',
        },
        {
          id: 55,
          type: 'text',
          name: 'bestsubjects',
          value: '',
        },
        {
          id: 56,
          type: 'text',
          name: 'educationplans',
          value: '',
        },
        {
          id: 57,
          type: 'text',
          name: 'goalsinlife',
          value: '',
        },
        {
          id: 58,
          type: 'text',
          name: 'completededucation',
          value: '',
        },
        {
          id: 59,
          type: 'select',
          name: 'pregnum',
          value: '',
        },
        {
          id: 60,
          type: 'date',
          name: 'p1dob',
          value: '',
        },
        {
          id: 61,
          type: 'text',
          name: 'p1conceive',
          value: '',
        },
        {
          id: 62,
          type: 'text',
          name: 'p1birthweight',
          value: '',
        },
        {
          id: 63,
          type: 'select',
          name: 'p1outcome',
          value: '',
        },
        {
          id: 64,
          type: 'date',
          name: 'p2dob',
          value: '',
        },
        {
          id: 65,
          type: 'text',
          name: 'p2conceive',
          value: '',
        },
        {
          id: 66,
          type: 'text',
          name: 'p2birthweight',
          value: '',
        },
        {
          id: 67,
          type: 'select',
          name: 'p2outcome',
          value: '',
        },
        {
          id: 68,
          type: 'date',
          name: 'p3dob',
          value: '',
        },
        {
          id: 69,
          type: 'text',
          name: 'p3conceive',
          value: '',
        },
        {
          id: 70,
          type: 'text',
          name: 'p3birthweight',
          value: '',
        },
        {
          id: 71,
          type: 'select',
          name: 'p3outcome',
          value: '',
        },
        {
          id: 72,
          type: 'textarea',
          name: 'pregcomplication',
          value: '',
        },
        {
          id: 73,
          type: 'radio',
          name: 'difficultconception',
          value: '',
        },
        {
          id: 74,
          type: 'textarea',
          name: 'difficultconexp',
          value: '',
        },
        {
          id: 75,
          type: 'radio',
          name: 'twinstriplets',
          value: '',
        },
        {
          id: 76,
          type: 'textarea',
          name: 'twinstripexp',
          value: '',
        },
        {
          id: 77,
          type: 'text',
          name: 'obgynname',
          value: '',
        },
        {
          id: 78,
          type: 'text',
          name: 'obgynnumber',
          value: '',
        },
        {
          id: 79,
          type: 'date',
          name: 'lastobvisit',
          value: '',
        },
        {
          id: 80,
          type: 'date',
          name: 'lastpapsmear',
          value: '',
        },
        {
          id: 81,
          type: 'text',
          name: 'papresults',
          value: '',
        },
        {
          id: 82,
          type: 'radio',
          name: 'changeweight',
          value: '',
        },
        {
          id: 83,
          type: 'text',
          name: 'weightchange',
          value: '',
        },
        {
          id: 84,
          type: 'radio',
          name: 'birthcontrol',
          value: '',
        },
        {
          id: 85,
          type: 'text',
          name: 'bcmethod',
          value: '',
        },
        {
          id: 86,
          type: 'text',
          name: 'bcmethodusage',
          value: '',
        },
        {
          id: 87,
          type: 'radio',
          name: 'bcmethodcomplication',
          value: '',
        },
        {
          id: 88,
          type: 'radio',
          name: 'regcycles',
          value: '',
        },
        {
          id: 89,
          type: 'textarea',
          name: 'cycledetails',
          value: '',
        },
        {
          id: 90,
          type: 'text',
          name: 'historymigraine',
          title: 'Migraines',
          value: '',
        },
        {
          id: 91,
          type: 'text',
          name: 'downs',
          title: "Down's Syndrome",
          value: '',
        },
        {
          id: 92,
          type: 'text',
          name: 'mental',
          title: 'Mental retardation',
          value: '',
        },
        {
          id: 93,
          type: 'text',
          name: 'siezure',
          title: 'Seizure disorder/Epilepsy',
          value: '',
        },
        {
          id: 94,
          type: 'text',
          name: 'muscular',
          title: 'Muscular Dystrophy',
          value: '',
        },
        {
          id: 95,
          type: 'text',
          name: 'deafness',
          title: 'Deafness or Hearing Problems',
          value: '',
        },
        {
          id: 96,
          type: 'text',
          name: 'blindness',
          title: 'Blindness or Color Blindness',
          value: '',
        },
        {
          id: 97,
          type: 'text',
          name: 'glasses',
          title: 'Corrective Lenses/Glasses',
          value: '',
        },
        {
          id: 98,
          type: 'text',
          name: 'glaucoma',
          title: 'Glaucoma',
          value: '',
        },
        {
          id: 99,
          type: 'text',
          name: 'sighted',
          title: 'Nearsighted or Farsighted',
          value: '',
        },
        {
          id: 100,
          type: 'text',
          name: 'schizophrenia',
          title: 'Schizophrenia',
          value: '',
        },
        {
          id: 101,
          type: 'text',
          name: 'depression',
          title: 'Suffers from Depression',
          value: '',
        },
        {
          id: 102,
          type: 'text',
          name: 'birthdefect',
          title: 'Serious Birth defect',
          value: '',
        },
        {
          id: 103,
          type: 'text',
          name: 'heartproblems',
          title: 'Heart Problems',
          value: '',
        },
        {
          id: 104,
          type: 'text',
          name: 'miscarriage',
          title: 'More than 2 miscarriages',
          value: '',
        },
        {
          id: 105,
          type: 'text',
          name: 'skindiscoloration',
          title: 'Skin discoloration/spots/rashes',
          value: '',
        },
        {
          id: 106,
          type: 'text',
          name: 'cancer',
          title: 'Cancer - what type',
          value: '',
        },
        {
          id: 107,
          type: 'text',
          name: 'hemophilia',
          title: 'Severe bleeding tendency (hemophilia)',
          value: '',
        },
        {
          id: 108,
          type: 'text',
          name: 'stroke',
          title: 'Stroke',
          value: '',
        },
        {
          id: 109,
          type: 'text',
          name: 'ulcer',
          title: 'Ulcer',
          value: '',
        },
        {
          id: 110,
          type: 'text',
          name: 'ovariancyst',
          title: 'Ovarian Cysts',
          value: '',
        },
        {
          id: 111,
          type: 'text',
          name: 'uterinefibroid',
          title: 'Uterine Fibroid',
          value: '',
        },
        {
          id: 112,
          type: 'text',
          name: 'asthma',
          title: 'Asthma',
          value: '',
        },
        {
          id: 113,
          type: 'text',
          name: 'hyperactivity',
          title: 'Hyperactivity Cysts',
          value: '',
        },
        {
          id: 114,
          type: 'text',
          name: 'alcoholism',
          title: 'Alcoholism',
          value: '',
        },
        {
          id: 115,
          type: 'text',
          name: 'drugaddiction',
          title: 'Drug Addiction',
          value: '',
        },
        {
          id: 116,
          type: 'text',
          name: 'learningdisability',
          title: 'Learning Disability',
          value: '',
        },
        {
          id: 117,
          type: 'text',
          name: 'diabetes',
          title: 'Diabetes',
          value: '',
        },
        {
          id: 118,
          type: 'text',
          name: 'autism',
          title: 'Autism',
          value: '',
        },

        {
          id: 119,
          type: 'radio',
          name: 'smoker',
          title: '',
          value: '',
        },
        {
          id: 120,
          type: 'text',
          name: 'smoker1',
          title: '',
          value: '',
        },
        {
          id: 121,
          type: 'radio',
          name: 'drinkalcohol',
          title: '',
          value: '',
        },
        {
          id: 122,
          type: 'text',
          name: 'amountalcohol',
          title: '',
          value: '',
        },
        {
          id: 123,
          type: 'radio',
          name: 'dietpills',
          title: '',
          value: '',
        },
        {
          id: 124,
          type: 'date',
          name: 'dietpills_lastused',
          title: '',
          value: '',
        },
        {
          id: 125,
          type: 'radio',
          name: 'druguse',
          title: '',
          value: '',
        },
        {
          id: 126,
          type: 'text',
          name: 'druguse_drugname',
          title: '',
          value: '',
        },
        {
          id: 127,
          type: 'date',
          name: 'druguse_lastused',
          title: '',
          value: '',
        },
        {
          id: 128,
          type: 'radio',
          name: 'transfusion',
          title: '',
          value: '',
        },
        {
          id: 129,
          type: 'textarea',
          name: 'transfusionexp',
          title: '',
          value: '',
        },
        {
          id: 130,
          type: 'radio',
          name: 'counseling',
          title: '',
          value: '',
        },
        {
          id: 131,
          type: 'textarea',
          name: 'counselingexp',
          title: '',
          value: '',
        },
        {
          id: 132,
          type: 'radio',
          name: 'psychillness',
          title: '',
          value: '',
        },
        {
          id: 133,
          type: 'textarea',
          name: 'psychillnessexp',
          title: '',
          value: '',
        },
        {
          id: 134,
          type: 'radio',
          name: 'arrests',
          title: '',
          value: '',
        },
        {
          id: 135,
          type: 'textarea',
          name: 'arrestsexp',
          title: '',
          value: '',
        },
        {
          id: 136,
          type: 'radio',
          name: 'hivaids',
          title: '',
          value: '',
        },
        {
          id: 137,
          type: 'textarea',
          name: 'medicalproblems',
          title: '',
          value: '',
        },
        {
          id: 138,
          type: 'textarea',
          name: 'currentmeds',
          title: '',
          value: '',
        },
        {
          id: 139,
          type: 'textarea',
          name: 'yearmeds',
          title: '',
          value: '',
        },
        {
          id: 140,
          type: 'textarea',
          name: 'hospitalizations',
          title: '',
          value: '',
        },
        {
          id: 141,
          type: 'multiple',
          name: 'STD',
          title: '',
          value: '',
        },
        {
          id: 142,
          type: 'radio',
          name: 'viewable_recipient',
          title: '',
          value: '',
        },
        {
          id: 143,
          type: 'radio',
          name: 'completedmedical',
          title: '',
          value: '',
        },
        {
          id: 144,
          type: 'select',
          name: 'father2',
          title: '',
          value: '',
        },
        {
          id: 145,
          type: 'select',
          name: 'father1',
          title: '',
          value: '',
        },

        {
          id: 146,
          type: 'select',
          name: 'mother2',
          title: '',
          value: '',
        },
        {
          id: 147,
          type: 'select',
          name: 'mother1',
          title: '',
          value: '',
        },
        {
          id: 148,
          type: 'select',
          name: 'numchildren',
          title: '',
          value: '',
        },
        {
          id: 149,
          type: 'select',
          name: 'childsex1',
          title: '',
          value: '',
        },
        {
          id: 150,
          type: 'date',
          name: 'childage1',
          title: '',
          value: '',
        },
        {
          id: 151,
          type: 'select',
          name: 'childeye1',
          title: '',
          value: '',
        },
        {
          id: 152,
          type: 'select',
          name: 'childhair1',
          title: '',
          value: '',
        },
        {
          id: 153,
          type: 'select',
          name: 'childhealth1',
          title: '',
          value: '',
        },
        {
          id: 154,
          type: 'select',
          name: 'childsex2',
          title: '',
          value: '',
        },
        {
          id: 155,
          type: 'date',
          name: 'childage2',
          title: '',
          value: '',
        },
        {
          id: 156,
          type: 'select',
          name: 'childeye2',
          title: '',
          value: '',
        },
        {
          id: 157,
          type: 'select',
          name: 'childhair2',
          title: '',
          value: '',
        },
        {
          id: 158,
          type: 'select',
          name: 'childhealth2',
          title: '',
          value: '',
        },
        {
          id: 159,
          type: 'select',
          name: 'childsex3',
          title: '',
          value: '',
        },
        {
          id: 160,
          type: 'date',
          name: 'childage3',
          title: '',
          value: '',
        },
        {
          id: 161,
          type: 'select',
          name: 'childeye3',
          title: '',
          value: '',
        },
        {
          id: 162,
          type: 'select',
          name: 'childhair3',
          title: '',
          value: '',
        },
        {
          id: 163,
          type: 'select',
          name: 'childhealth3',
          title: '',
          value: '',
        },
        {
          id: 164,
          type: 'select',
          name: 'childsex4',
          title: '',
          value: '',
        },
        {
          id: 165,
          type: 'date',
          name: 'childage4',
          title: '',
          value: '',
        },
        {
          id: 166,
          type: 'select',
          name: 'childeye4',
          title: '',
          value: '',
        },
        {
          id: 167,
          type: 'select',
          name: 'childhair4',
          title: '',
          value: '',
        },
        {
          id: 168,
          type: 'select',
          name: 'childhealth4',
          title: '',
          value: '',
        },
        {
          id: 169,
          type: 'radio',
          name: 'completedgenetic',
          title: '',
          value: '',
        },

        {
          id: 170,
          type: 'textarea',
          name: 'personality',
          title: '',
          value: '',
        },
        {
          id: 171,
          type: 'textarea',
          name: 'hobbies',
          title: '',
          value: '',
        },
        {
          id: 172,
          type: 'textarea',
          name: 'philosophy',
          title: '',
          value: '',
        },
        {
          id: 173,
          type: 'select',
          name: 'instrument',
          title: '',
          value: '',
        },
        {
          id: 174,
          type: 'textarea',
          name: 'instrument1',
          title: '',
          value: '',
        },
        {
          id: 175,
          type: 'select',
          name: 'arttalent',
          title: '',
          value: '',
        },
        {
          id: 176,
          type: 'textarea',
          name: 'arttalentexp',
          title: '',
          value: '',
        },
        {
          id: 177,
          type: 'select',
          name: 'otherlang',
          title: '',
          value: '',
        },
        {
          id: 178,
          type: 'textarea',
          name: 'otherlangexp',
          title: '',
          value: '',
        },
        {
          id: 179,
          type: 'select',
          name: 'athletic',
          title: '',
          value: '',
        },
        {
          id: 180,
          type: 'textarea',
          name: 'athleticexp',
          title: '',
          value: '',
        },
        {
          id: 181,
          type: 'text',
          name: 'favfood',
          title: '',
          value: '',
        },
        {
          id: 182,
          type: 'text',
          name: 'favcolor',
          title: '',
          value: '',
        },
        {
          id: 183,
          type: 'text',
          name: 'favseason',
          title: '',
          value: '',
        },
        {
          id: 184,
          type: 'text',
          name: 'favholiday',
          title: '',
          value: '',
        },
        {
          id: 185,
          type: 'text',
          name: 'favsports',
          title: '',
          value: '',
        },
        {
          id: 186,
          type: 'text',
          name: 'favsubject',
          title: '',
          value: '',
        },
        {
          id: 187,
          type: 'text',
          name: 'favbook',
          title: '',
          value: '',
        },
        {
          id: 188,
          type: 'text',
          name: 'favsong',
          title: '',
          value: '',
        },
        {
          id: 189,
          type: 'text',
          name: 'favtv',
          title: '',
          value: '',
        },
        {
          id: 190,
          type: 'text',
          name: 'favsinger',
          title: '',
          value: '',
        },
        {
          id: 191,
          type: 'text',
          name: 'favmovie',
          title: '',
          value: '',
        },
        {
          id: 192,
          type: 'text',
          name: 'favhobbie',
          title: '',
          value: '',
        },
        {
          id: 193,
          type: 'text',
          name: 'whydonate',
          title: '',
          value: '',
        },
        {
          id: 194,
          type: 'text',
          name: 'valuemost',
          title: '',
          value: '',
        },
        {
          id: 195,
          type: 'text',
          name: 'proudof',
          title: '',
          value: '',
        },
        {
          id: 196,
          type: 'text',
          name: 'believe',
          title: '',
          value: '',
        },
        {
          id: 197,
          type: 'text',
          name: 'dislike',
          title: '',
          value: '',
        },
        {
          id: 198,
          type: 'text',
          name: 'immediategoals',
          title: '',
          value: '',
        },
        {
          id: 199,
          type: 'text',
          name: 'futureplans',
          title: '',
          value: '',
        },
        {
          id: 200,
          type: 'text',
          name: 'childhoodexp',
          title: '',
          value: '',
        },
        {
          id: 201,
          type: 'text',
          name: 'lottery',
          title: '',
          value: '',
        },
        {
          id: 202,
          type: 'multiple',
          name: 'willworkwith',
          title: '',
          value: '',
        },
        {
          id: 203,
          type: 'text',
          name: 'qualities',
          title: '',
          value: '',
        },
        {
          id: 204,
          type: 'text',
          name: 'contactcouple',
          title: '',
          value: '',
        },
        {
          id: 205,
          type: 'text',
          name: 'relationshipbirth',
          title: '',
          value: '',
        },
        {
          id: 206,
          type: 'text',
          name: 'childadopt',
          title: '',
          value: '',
        },
        {
          id: 207,
          type: 'text',
          name: 'childadoptexp',
          title: '',
          value: '',
        },
        {
          id: 208,
          type: 'text',
          name: 'respondtochild',
          title: '',
          value: '',
        },
        {
          id: 209,
          type: 'radio',
          name: 'anondonation',
          title: '',
          value: '',
        },
        {
          id: 210,
          type: 'text',
          name: 'supportfriends',
          title: '',
          value: '',
        },
        {
          id: 211,
          type: 'text',
          name: 'supportparents',
          title: '',
          value: '',
        },
        {
          id: 212,
          type: 'text',
          name: 'supportpartner',
          title: '',
          value: '',
        },
        {
          id: 213,
          type: 'radio',
          name: 'nosex',
          title: '',
          value: '',
        },
        {
          id: 214,
          type: 'textarea',
          name: 'messageparents',
          title: '',
          value: '',
        },
        {
          id: 215,
          type: 'radio',
          name: 'completedtraits',
          title: '',
          value: '',
        },
        {
          id: 216,
          type: 'radio',
          name: 'donatedbefore',
          title: '',
          value: '',
        },
        {
          id: 217,
          type: 'radio',
          name: 'donatedfrozenbefore',
          title: '',
          value: '',
        },
        {
          id: 218,
          type: 'date',
          name: 'cycles[cycledate]',
          title: '',
          value: '',
        },
        {
          id: 219,
          type: 'date',
          name: 'frozen_cycles[thaw_date]',
          title: '',
          value: '',
        },
        {
          id: 220,
          type: 'radio',
          name: 'agreement',
          title: '',
          value: '',
        },
        {
          id: 221,
          type: 'text',
          name: 'partnername',
          title: "Husband/Partner's Name",
          value: '',
        },
        {
          id: 222,
          type: 'text',
          name: 'partnerage',
          title: "Husband/Partner's Age",
          value: '',
        },
        {
          id: 223,
          type: 'radio',
          name: 'partneroccupation',
          title: 'Husband/Partners Occupation',
          value: '',
        },
        {
          id: 224,
          type: 'text',
          name: 'numdivorces',
          title: 'Number of Divorces',
          value: '',
        },
        {
          id: 225,
          type: 'date',
          name: 'divorcedates',
          title: 'Date of last divorce',
          value: '',
        },

        {
          id: 226,
          type: 'date',
          name: 'p4dob',
          value: '',
        },
        {
          id: 227,
          type: 'text',
          name: 'p4conceive',
          value: '',
        },
        {
          id: 228,
          type: 'text',
          name: 'p4birthweight',
          value: '',
        },
        {
          id: 229,
          type: 'select',
          name: 'p4outcome',
          value: '',
        },
        {
          id: 230,
          type: 'date',
          name: 'p5dob',
          value: '',
        },
        {
          id: 231,
          type: 'text',
          name: 'p5conceive',
          value: '',
        },
        {
          id: 232,
          type: 'text',
          name: 'p5birthweight',
          value: '',
        },
        {
          id: 233,
          type: 'select',
          name: 'p5outcome',
          value: '',
        },
        {
          id: 234,
          type: 'select',
          name: 'bloodtype',
          value: '',
          title: 'Blood Type',
        },
        {
          id: 235,
          type: 'select',
          name: 'sexorient',
          value: '',
          title: 'Sexual Orientation',
        },
        {
          id: 236,
          type: 'radio',
          name: 'adopted',
          value: 'No',
          title: 'Were you adopted?',
        },
        {
          id: 237,
          type: 'textarea',
          name: 'adopted2',
          value: '',
          title: 'Do you have information about your biological parents?',
        },
        {
          id: 238,
          type: 'text',
          name: 'tattoo1',
          value: '',
        },
        {
          id: 239,
          type: 'text',
          name: 'piercing1',
          value: '',
        },
        {
          id: 240,
          type: 'text',
          name: 'agencyname',
          value: '',
        },
        {
          id: 241,
          type: 'textarea',
          name: 'bankexp',
          value: '',
          title: 'Please Explain',
        },
        {
          id: 242,
          type: 'textarea',
          name: 'psychexp',
          value: '',
          title: 'Please Explain',
        },
        {
          id: 243,
          type: 'textarea',
          name: 'arrestedexp',
          value: '',
          title: 'Please Explain',
        },
        {
          id: 244,
          type: 'textarea',
          name: 'legalcasesexp',
          value: '',
          title: 'Please Explain',
        },
        {
          id: 245,
          type: 'textarea',
          name: 'subabuseexp',
          value: '',
          title: 'Please Explain',
        },
        {
          id: 246,
          type: 'textarea',
          name: 'highschool',
          value: '',
          title: 'High School',
        },
        {
          id: 247,
          type: 'textarea',
          name: 'college',
          value: '',
          title: 'Currently In College',
        },
        {
          id: 248,
          type: 'textarea',
          name: 'occupationalcollege',
          value: '',
          title: 'Occupational College',
        },
        {
          id: 249,
          type: 'textarea',
          name: 'technicalcollege',
          value: '',
          title: 'Technical College',
        },
        {
          id: 250,
          type: 'textarea',
          name: 'associatedegree',
          value: '',
          title: 'Associate Degree',
        },
        {
          id: 251,
          type: 'textarea',
          name: 'bachelordegree',
          value: '',
          title: 'Bachelor Degree',
        },
        {
          id: 252,
          type: 'textarea',
          name: 'mastersdegree',
          value: '',
          title: "Master's Degree",
        },
        {
          id: 253,
          type: 'textarea',
          name: 'phdmddegree',
          value: '',
          title: 'Ph.D. or M.D. Degree',
        },
        {
          id: 254,
          type: 'textarea',
          name: 'doctoralprogram',
          value: '',
          title: 'Doctoral Program',
        },
        {
          id: 255,
          type: 'textarea',
          name: 'othereducation',
          value: '',
          title: 'Other Education',
        },
      ],

      related_items: [
        {
          id: 0,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 1,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 2,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 3,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 4,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 5,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 6,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 7,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 8,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 9,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 10,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 11,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 12,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 13,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 14,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 15,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 16,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 17,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 18,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
        {
          id: 19,
          relative: 0,
          name: '',
          age: '',
          occupation: '',
          haircolor: '',
          eyecolor: '',
          height: '',
          weight_: '',
          healthstatus: '',
          deceased: '',
        },
      ],

      educationlevel_items: [
        {
          id: 0,
          title: 'GED',
          value: 'GED',
          checked: false,
        },
        {
          id: 1,
          title: 'High School Graduate',
          value: 'Highschool Graduate',
          checked: false,
        },
        {
          id: 2,
          title: 'Currently in College',
          value: 'Currently in College',
          checked: false,
        },
        {
          id: 3,
          title: 'Occupational School',
          value: 'Occupational School',
          checked: false,
        },
        {
          id: 4,
          title: 'Technical College',
          value: 'Technical College',
          checked: false,
        },
        {
          id: 5,
          title: 'Associate Degree',
          value: 'Associate Degree',
          checked: false,
        },
        {
          id: 6,
          title: 'Bachelor Degree',
          value: 'Bachelor Degree',
          checked: false,
        },
        {
          id: 7,
          title: "Master's Degree",
          value: "Master's Degree",
          checked: false,
        },
        {
          id: 8,
          title: 'Ph.D. or M.D. Degree',
          value: 'Ph.D. or M.D. Degree',
          checked: false,
        },
        {
          id: 9,
          title: 'Other Doctoral Program',
          value: 'Other Doctoral Program',
          checked: false,
        },
        {
          id: 10,
          title: 'Other Education',
          value: 'Other Education',
          checked: false,
        },
      ],

      willworkwith: [
        {
          id: 0,
          title: 'Same Sex Couple',
          value: 'gay',
          checked: false,
        },
        {
          id: 1,
          title: 'Single Person',
          value: 'single',
          checked: false,
        },
        {
          id: 2,
          title: 'Interracial Couple',
          value: 'interracial',
          checked: false,
        },
        {
          id: 3,
          title: 'Heterosexual Couple',
          value: 'hetero',
          checked: false,
        },
        {
          id: 4,
          title: 'Open to All',
          value: 'opentoall',
          checked: false,
        },
      ],

      std_items: [
        {
          id: 0,
          title: 'None',
          value: '',
          checked: false,
        },
        {
          id: 1,
          title: 'Chlamydiae',
          value: 'Chlamydia',
          checked: false,
        },
        {
          id: 2,
          title: 'Syphilis',
          value: 'Syphilis',
          checked: false,
        },
        {
          id: 3,
          title: 'Gonorrhea',
          value: 'Gonorrhea',
          checked: false,
        },
        {
          id: 4,
          title: 'HPV',
          value: 'HPV',
          checked: false,
        },
        {
          id: 5,
          title: 'Genital Herpes',
          value: 'Genital Herpes',
          checked: false,
        },
        {
          id: 6,
          title: 'Hepatitis B',
          value: 'Hepatitis B',
          checked: false,
        },
        {
          id: 7,
          title: 'Hepatitis C',
          value: 'Hepatitis C',
          checked: false,
        },
        {
          id: 8,
          title: 'Ovarian Cyst',
          value: 'Ovarian Cyst',
          checked: false,
        },
        {
          id: 9,
          title: 'PID',
          value: 'PID',
          checked: false,
        },
      ],
    }
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()

    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleTextareadChange = this.handleTextareadChange.bind(this)
    this.fileUploadHandler = this.fileUploadHandler.bind(this)

    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.acceptAgreement = this.acceptAgreement.bind(this)
    this.declineAgreement = this.declineAgreement.bind(this)
    this.p_info = ''
    this.id = 0
  }

  componentWillMount() {
    F_UTIL.check_login()
    this.id = global.r_id
    document.title = 'Donor Application'
  }

  componentDidMount() {
    this.handleFormChange()
    this.handlePhotoFormChange()
    this.get_donor_info()
  }

  parse_data(info) {
    const {
      form_items,
      related_items,
      educationlevel_items,
      std_items,
      willworkwith,
      photos,
    } = this.state
    var data = info.donor
    var fresh_cycles = info.freshCycles
    var frozen_cycles = info.frozenCycles
    var modalIsOpen = false
    for (var field in data) {
      console.log(field + ':' + data[field])
      /*
            if (field === 'race2') {
                console.log('Race2 Continue');
                form_items[22].value = data[field];
                continue;
            }
            */
      if (field === 'consent') {
        if (data[field] === 'yes') {
          modalIsOpen = false
        } else {
          modalIsOpen = true
        }
      }
      form_items.map(function (obj, i) {
        if (obj.name === field) {
          if (obj.type === 'date') {
            console.log(
              'Date-> ' + i + 'th, field:' + field + ', Val:' + data[field],
            )
            if (data[field] === '') form_items[i].value = ''
            else form_items[i].value = new Date(data[field])
            return true
          }
          form_items[i].value = data[field]
          console.log(i + ': Added')
          return true
        }
      })
    }

    var educationlevels = form_items[49].value.split(',')
    educationlevels.forEach(function (val) {
      //console.log('Db val:' + val);
      educationlevel_items.map(function (obj, i) {
        //console.log('Variable val:' + obj.value);
        if (obj.value === val) {
          obj.checked = true
          //console.log('Checked');
        }
      })
    })

    var stds = form_items[141].value.split(',')
    stds.forEach(function (val) {
      //console.log('Db val:' + val);
      std_items.map(function (obj, i) {
        //console.log('Variable val:' + obj.value);
        if (obj.value === val) {
          obj.checked = true
          //console.log('Checked');
        }
      })
    })

    var willwork = form_items[202].value.split(',')
    willwork.forEach(function (val) {
      //console.log('Db val:' + val);
      willworkwith.map(function (obj, i) {
        //console.log('Variable val:' + obj.value);
        if (obj.value === val) {
          obj.checked = true
          //console.log('Checked');
        }
      })
    })

    //parse relatives
    var data = info.relatives
    for (let i = 0; i < data.length; i++) {
      var item = data[i]

      if (item.age === '') {
        item.age = ''
      } else {
        item.age = new Date(item.age)
      }
      console.log('Item:' + item.id)

      related_items[i].name = item.relation
      related_items[i].relative = item.id
      related_items[i].age = item.age
      related_items[i].occupation = item.occupation
      related_items[i].haircolor = item.haircolor
      related_items[i].eyecolor = item.eyecolor
      related_items[i].height = item.height
      related_items[i].weight_ = item.weight_
      related_items[i].healthstatus = item.healthstatus
      related_items[i].deceased = item.deceased
      related_items[i].cod = item.cod
      related_items[i].dod = item.dod
    }

    //parse photos
    if (info.photos !== undefined && info.photos.length > 0) {
      for (var i = 0; i < info.photos.length; i++) {
        if (info.photos[i].default > 0) info.photos[i].checked = true
        else info.photos[i].checked = false

        photos.push(info.photos[i])
      }
    }

    //photos = info.photos;

    this.setState({
      form_items: form_items,
      related_items,
      educationlevel_items: educationlevel_items,
      std_items: std_items,
      willworkwith: willworkwith,
      photos: photos,
      modalIsOpen: modalIsOpen,
      frozenCycles: frozen_cycles,
      freshCycles: fresh_cycles,
    })
  }

  refreshPhotos() {
    var url = global.api_url + 'donor/get_photos/' + this.id
    console.log('Call API:', url)

    this.setState({ refreshImages: true })

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { rid: global.r_id },
      cache: false,
      crossDomain: true,
      success: function (response) {
        let photos = response.photos

        this.setState({ photos: photos, refreshImages: false })
      }.bind(this),
    })
  }

  fileUploadHandler() {
    const fd = new FormData()
    var _this = this

    fd.append('id', this.id)
    $.each($('#file')[0].files, function (i, file) {
      fd.append('image[]', file, file.name)
    })

    var url = global.api_url + 'donor/uploadImages/'
    //fd.append('image', this.state.imageFiles, this.state.imageFiles.name);
    axios
      .post(url, fd, {
        headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY },
        onUploadProgress: (ProgressEvent) => {
          var percent = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100,
          )
          console.log('Upload Progress:' + percent + '%')
          this.setState({ uploadingPrgress: percent })
        },
      })
      .then((res) => {
        console.log('Upload Completed, Try refreshing')

        this.setState({ uploading: false })
        this.refreshPhotos()
        //console.log('Res:' + res);
      })
  }

  fileSelectedHandler = (event) => {
    var error = false
    var msg = ''

    $.each($('#file')[0].files, function (i, file) {
      if (file.size > 10000000) {
        error = true
        msg = 'File size is too big'
        return
      }
    })

    if (error === true) {
      this.addNotification('danger', msg)
      return
    }
    this.setState({
      imageFiles: event.target.files[0],
      uploading: true,
      uploadingPrgress: 0,
    })

    this.fileUploadHandler()
  }

  get_donor_info() {
    var url = global.api_url + 'donor/detail/' + this.id
    console.log('Call API:', url)

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { rid: global.r_id },
      cache: false,
      crossDomain: true,
      success: function (response) {
        if (response.success === true) {
          //var info = JSON.parse(response);
          this.parse_data(response.info)
          this.setState({ load_data: 1 })
        } else {
          this.api_message = response.message
          this.setState({ load_data: -1 })
          console.log('Ajax API failed:', this.api_message)
          F_UTIL.goto_login()
        }
      }.bind(this),
    })
  }

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    })
  }

  changeStep(step, e) {
    // var $parent = $(e.target).closest('.step-box');
    // $('.step-contents').slideToggle('fast');
    const { steps } = this.state

    var active_step = ''

    console.log('Clicked Step:' + step)
    steps.map(function (obj, i) {
      if (steps[i].open === true) {
        active_step = i
        console.log('Active Step:' + active_step)
      }

      if (i !== step) {
        steps[i].open = false
      }
    })
    steps[step].open = !steps[step].open
    var height = 0
    if (active_step < step) height = $('.step-contents').height()
    this.setState({ steps: steps })

    var position = $(e.target).offset().top
    console.log('Height:' + height + ', Scroll Top:' + position)
    $('HTML').animate(
      {
        scrollTop: position - height - 10,
      },
      1000,
    )
  }

  changeFilterEducationLevel(ref, e) {
    console.log('Filter EducationLevel Clicked:' + ref)
    let items = this.state.educationlevel_items

    console.log(
      'Filter EducationLevel Clicked:index:' +
        ref +
        ', Title:' +
        items[ref].title +
        ', Value:' +
        items[ref].checked,
    )

    items[ref].checked = !items[ref].checked
    this.setState({ educationlevel_items: items })
  }

  changeFilterPhoto(ref, e) {
    console.log('Filter changeFilterPhoto Clicked:' + ref)
    let items = this.state.photos

    if (items[ref].default > 0) items[ref].default = 0
    else items[ref].default = 1

    items[ref].checked = !items[ref].checked

    if (items[ref].checked === true) {
      for (var i = 0; i < items.length; i++) {
        if (i !== ref) {
          items[i].checked = false
          items[i].default = 0
        }
      }
    }

    this.setState({ photos: items })
  }

  cancelUpload() {}

  removePhotoElement(photo_id) {
    var items = this.state.photos
    var new_items = []
    console.log('Remove Photo:' + photo_id)
    for (var i = items.length - 1; i >= 0; i--) {
      console.log(
        i + 'th Image:' + items[i].id + ', Caption:' + items[i].caption,
      )
      if (items[i].id === photo_id) {
        console.log('Item Removed:' + i)
        items.splice(i, 1)
      }
    }
    console.log('items', items)

    //new_items = JSON.parse(JSON.stringify(items));
    for (var i = 0; i < items.length; i++) {
      new_items.push(items[i])
    }

    console.log('new_items', new_items)
    this.setState({ photos: new_items })
  }

  onChangePhotoCaption(photo_id, e) {
    let items = this.state.photos

    for (let i = 0; i < items.length; i++) {
      if (items[i].id === photo_id) {
        items[i].caption = e.target.value
      }
    }
    this.setState({ photos: items })
  }

  removePhoto(photo_id, e) {
    var _this = this
    if (photo_id <= 0) return

    var params = {
      photo_id: photo_id,
    }

    var url = global.api_url + 'donor/remove_photo'
    console.log(url)

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: params,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('remove_photo Success')
        //this.addNotification();
        if (rsp.success === true) {
          _this.removePhotoElement(photo_id)
          _this.addNotification('success', 'Photo Removed!')
        } else if (rsp.credential === false) {
          F_UTIL.goto_login()
        } else {
          _this.addNotification('danger', 'Save Error!')
        }
      }.bind(_this),
    })
  }

  changeNumber(e) {
    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode)
    console.log('Event:' + e.target.name + ',' + e.target.value + ',' + key)
    if (isNaN(key)) {
      console.log('No Number')
      e.preventDefault()
      return false
    }
  }

  changeSelectOptions(ref, e) {
    let items = this.state.select_items
    var value = e.target.value
    items[ref].value = value

    this.setState({ select_items: items })
  }

  changeFilterSTD(ref, e) {
    console.log('Filter STD Clicked:' + ref)
    let items = this.state.std_items

    console.log(
      'Filter STD Clicked:index:' +
        ref +
        ', Title:' +
        items[ref].title +
        ', Value:' +
        items[ref].checked,
    )

    items[ref].checked = !items[ref].checked

    if (ref === 0 && items[ref].checked === true) {
      items.forEach((ref, index) => {
        if (index !== 0) items[index].checked = false
      })

      $('#resetstds').attr('disabled', true)
      $('.rc-checkbox input[name="values[STD][]"]:not(#resetstds)').attr(
        'checked',
        false,
      )
    } else {
      items[0].checked = false
      $('#resetstds').attr('checked', false)
      $('#resetstds').attr('disabled', false)
    }

    this.setState({ std_items: items })
  }

  changeWillingworkWith(ref, e) {
    console.log('Filter willworkwith Clicked:' + ref)
    let items = this.state.willworkwith

    console.log(
      'Filter willworkwith Clicked:index:' +
        ref +
        ', Title:' +
        items[ref].title +
        ', Value:' +
        items[ref].checked,
    )

    items[ref].checked = !items[ref].checked
    this.setState({ willworkwith: items })
  }

  handleDateChange(name, date) {
    console.log('Name:' + name + ',Date:' + date)
    var dateFormat = require('dateformat')
    const { form_items, cycledate, thaw_date } = this.state

    if (name === 'cycledate') {
      this.setState({ cycledate: date })
      return
    } else if (name === 'thaw_date') {
      this.setState({ thaw_date: date })
      return
    }

    form_items.map(function (obj, i) {
      if (obj.name === name) {
        form_items[i].value = date
        console.log('Date Added')
        return true
      }
    })

    this.setState({ form_items: form_items })
    var newdate = dateFormat(date, 'mm/dd/yyyy')
    console.log(newdate)
    this.postSaveReq(name, newdate)
  }

  handleRadioChange(e) {
    console.log('Radio Click:' + value + ', name:' + name)
    console.log('Radio Event:' + e)
    const { form_items } = this.state
    var name = e.target.name
    var value = e.target.value
    form_items.map(function (obj, i) {
      if (obj.name === name) {
        form_items[i].value = value
        return true
      }
    })

    this.setState({ form_items: form_items })
  }

  handleRelativeDateChange(relative_id, date) {
    const { related_items } = this.state
    var dateFormat = require('dateformat')

    related_items.map(function (obj, i) {
      if (obj.relative === relative_id) {
        related_items[i].age = date
        return true
      }
    })

    var newdate = dateFormat(date, 'mm/dd/yyyy')
    console.log(newdate)
    this.postSaveReq('age', newdate, relative_id)

    this.setState({ related_items: related_items })
  }

  postSaveReq(name, value, relative_id = 0) {
    var _this = this
    var donor_id = this.id

    var params = {
      column: name,
      value: value,
    }

    if (relative_id > 0) params.relative_id = relative_id

    var url = global.api_url + 'donor/save_application/' + donor_id
    console.log(url)

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: params,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        //this.addNotification();
        if (rsp.success === true) {
          //_this.addNotification('success', 'Saved!');
          console.log('save_application Success')
        } else if (rsp.credential === false) {
          F_UTIL.goto_login()
        } else {
          _this.addNotification('danger', 'Save Error!')
        }
      }.bind(_this),
    })
  }

  postSubmitReq() {
    var _this = this
    var donor_id = this.id

    var url = global.api_url + 'donor/submit_application/' + donor_id
    console.log(url)

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        //this.addNotification();
        if (rsp.success === true) {
          //_this.addNotification('success', 'Saved!');
          console.log('submit_application Success')
          _this.addNotification(
            'success',
            'Your application has been submitted',
          )
        } else if (rsp.credential === false) {
          F_UTIL.goto_login()
        } else {
          _this.addNotification('danger', 'Save Error!')
        }
      }.bind(_this),
    })
  }

  handlePhotoFormChange() {
    var donor_id = this.id
    var _this = this

    $('#photo_form').on('change', ':input', function () {
      var $el = $(this)
      var type = $el.attr('type')
      var name = $el.attr('name')
      var value = $el.val()
      var photo_id = 0

      if ($el.hasClass('ignore')) {
        console.log('Ignore')
        return
      }

      if ($el.attr('data-photo_id') !== '') {
        photo_id = $el.attr('data-photo_id')
      }
      console.log(
        'Photo Id:' +
          photo_id +
          ',name:' +
          $el.attr('name') +
          ', type:' +
          type +
          ',value:' +
          $el.val(),
      )

      var params = {
        column: name,
        value: value,
        photo_id: photo_id,
      }

      var url = global.api_url + 'donor/save_photo/' + donor_id
      console.log(url)

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: params,
        cache: false,
        crossDomain: true,
        success: function (rsp) {
          console.log('save_photo Success')
          //this.addNotification();
          if (rsp.success === true) {
            _this.addNotification('success', 'Saved!')
          } else if (rsp.credential === false) {
            F_UTIL.goto_login()
          } else {
            _this.addNotification('danger', 'Save Error!')
          }
        }.bind(_this),
      })
    })
  }

  handleFormChange() {
    var donor_id = this.id
    var _this = this
    const { form_items, related_items } = this.state
    $('#donor_form').on('change', ':input', function () {
      //console.log('hookFormChange');
      var $el = $(this)
      var type = $el.attr('type')
      var name = $el.attr('name')
      var value = $el.val()
      var value_ = ''
      var relative_id = 0
      console.log(
        'name:' + $el.attr('name') + ', type:' + type + ',value:' + $el.val(),
      )

      if ($el.hasClass('ignore')) {
        console.log('Ignore')
        return
      }

      if ($el.attr('type') === 'radio') {
        $el.closest('div').find('label').removeClass('active')
        $el.parent().addClass('active')
      }

      if (name === 'race2[]') {
        var race = []
        name = 'race2'
        var raceInputs = $('#donor_form').find('select[name^="race2"]')

        $.each(raceInputs, function (index, val) {
          var v = $(this).val()
          if (v.length > 1 && v !== '') {
            race.push(v)
          }
        })
        value = race.join('|')
        value_ = race.join(',')
      }

      if (name === 'STD[]') {
        var stds = []
        name = 'STD'
        var stdInputs = $('input[name^="STD"]:checked')

        $.each(stdInputs, function (index, val) {
          if ($(this).prop('checked') === true) {
            var v = $(this).val()
            stds.push(v)
          }
        })
        value = stds.join(',')
        if ($(this).attr('id') === 'resetstds') {
          value = null
        }
      }

      if (name === 'educationlevel[]') {
        var educationlevel = []
        name = 'educationlevel'
        var elInputs = $('#donor_form').find(
          'input[name^="educationlevel"]:checked',
        )

        $.each(elInputs, function (index, val) {
          var v = $(this).val()

          if (v.length > 1 && v !== '') {
            educationlevel.push(v)
          }
        })
        value = educationlevel.join(',')
      }

      if (name === 'willworkwith[]') {
        var willworkwith = []
        name = 'willworkwith'
        var wwwInputs = $('#donor_form').find(
          'input[name^="willworkwith"]:checked',
        )

        $.each(wwwInputs, function (index, val) {
          var v = $(this).val()

          if (v.length > 1 && v !== '') {
            willworkwith.push(v)
          }
        })
        value = willworkwith.join(',')
      }

      if ($el.attr('data-relative-id') !== '') {
        relative_id = $el.attr('data-relative-id')
      }

      _this.postSaveReq(name, value, relative_id)

      if (relative_id > 0) {
        related_items.map(function (obj, i) {
          if (obj.relative === relative_id) {
            for (var field in obj) {
              if (field === name) {
                related_items[i][field] = value
              }
            }
          }
        })
      } else {
        form_items.map(function (obj, i) {
          if (obj.name === name) {
            if (name === 'race2') form_items[i].value = value_
            else form_items[i].value = value
            console.log('Name: ' + name + ', Value: ' + value)
          }
        })
      }

      _this.setState({ form_items: form_items, related_items: related_items })
    })
  }

  onAddNewRelative(e) {
    e.preventDefault()
    var _this = this
    var relation = $('#new_relation').val()
    if (!relation) {
      $('#new_relation').addClass('has-error')
      return
    }

    $('#new_relation').removeClass('has-error')
    var ajaxurl = global.api_url + 'donor/add_relation/' + this.id
    var formdata = 'relation=' + relation

    var params = {
      data: formdata,
    }

    $.post(ajaxurl, formdata, function (rsp) {
      var ret = JSON.parse(rsp)
      var bfound = false
      if (ret.success > 0) {
        const { related_items } = _this.state
        var relative_id = ret.success
        $('#new_relation').val('')
        $('#new_relation').removeClass('has-error')

        related_items.map(function (obj, i) {
          console.log(
            'Relative map:' +
              i +
              ', Relation:' +
              obj.name +
              ', Id:' +
              obj.relative_id,
          )
          if (obj.relative === 0 && bfound === false) {
            related_items[i]['id'] = i
            related_items[i]['name'] = relation
            related_items[i]['relative'] = relative_id
            bfound = true
          }
        })

        _this.setState({ related_items: related_items })

        _this.addNotification('success', 'Added new Relative!')
      } else {
        _this.addNotification('danger', 'Error to add new Relative!')
      }
    })
  }

  onClickInsertCycle(e) {
    e.preventDefault()
    var doIt = false
    var _this = this
    $('#new_cycle :input')
      .not(':button')
      .each(function (i, v) {
        if (!$(v).val()) {
          $(v).addClass('has-error')
          doIt = true
        } else {
          $(v).removeClass('has-error')
        }
      })

    var dateFormat = require('dateformat')
    var newdate = dateFormat(this.state.cycledate, 'mm/dd/yyyy')
    var ajaxurl = global.api_url + 'donor/add_cycle/' + this.id
    var formdata = $('#new_cycle :input').serialize()
    formdata += '&cycledate=' + newdate

    var params = {
      data: formdata,
    }

    if (!doIt) {
      $.post(ajaxurl, formdata, function (rsp) {
        var ret = JSON.parse(rsp)
        if (ret.success > 0) {
          $('#new_cycle :input')
            .not(':button')
            .each(function (i, v) {
              $(v).val('')
              $(v).removeClass('has-error')
            })
          _this.setState({ cycledate: '', freshCycles: ret.freshCycles })
          _this.addNotification('success', 'Added new Fresh Cycle!')
        } else {
          _this.addNotification('danger', 'Error to add new Fresh Cycle!')
        }
      })
    }
  }

  onClickInsertFrozenCycle(e) {
    e.preventDefault()
    var doIt = false
    var _this = this
    $('#new_frozen_cycle :input')
      .not(':button')
      .each(function (i, v) {
        if (!$(v).val()) {
          $(v).addClass('has-error')
          doIt = true
        } else {
          $(v).removeClass('has-error')
        }
      })

    var dateFormat = require('dateformat')
    var newdate = dateFormat(this.state.thaw_date, 'mm/dd/yyyy')
    var ajaxurl = global.api_url + 'donor/add_frozen_cycle/' + this.id
    var formdata = $('#new_frozen_cycle :input').serialize()
    formdata += '&thaw_date=' + newdate

    var params = {
      data: formdata,
    }

    if (!doIt) {
      $.post(ajaxurl, formdata, function (rsp) {
        var ret = JSON.parse(rsp)
        if (ret.success > 0) {
          $('#new_frozen_cycle :input')
            .not(':button')
            .each(function (i, v) {
              $(v).val('')
              $(v).removeClass('has-error')
            })
          _this.setState({ cycledate: '', frozenCycles: ret.frozenCycles })
          _this.addNotification('success', 'Added new Frozen Cycle!')
        } else {
          _this.addNotification('danger', 'Error to add new Frozen Cycle!')
        }
      })
    }
  }

  handleTextareadChange(e) {
    const { form_items } = this.state
    var name = e.target.name
    var val = e.target.value

    form_items.map(function (obj, i) {
      if (obj.name === name) {
        form_items[i].value = val
        console.log(i + ': Added')
        return true
      }
    })

    this.setState({ form_items: form_items })
  }

  render_header() {
    return (
      <div className="heading-box text-left">
        <h1 className="s-heading1 mt__70">Donor Application</h1>

        <h2 className="account-heading mt__50">
          Welcome to Egg Donation Inc.!
        </h2>

        <p className="account-info-content text-left">
          Thank you for your interest in becoming an egg donor with Egg
          Donation, Inc. We understand the application process can be demanding
          and we know these questions are of a sensitive nature. We appreciate
          your candor and honesty. We adhere to the ASRM and FDA guidelines and
          will always work to sustain the highest ethical standards.
        </p>

        <p className="account-info-content text-left pt-3">
          Helping someone experience the miracle of life by becoming an egg
          donor is an extraordinary feeling and we look forward to being on this
          amazing journey with you. For more information on our program and the
          process of egg donation, please view our{' '}
          <a href="https://eggdonor.com/donors/donors-faq/" target="_blank">
            {' '}
            Frequently Asked Questions
          </a>{' '}
          page.
        </p>
      </div>
    )
  }

  render_alert() {
    return (
      <div className="step-alert">
        <img src={img_alert} />
      </div>
    )
  }

  render_step1() {
    const { form_items, select_items, steps } = this.state

    const error = steps[0].error
    if (steps[0].open === false) {
      return (
        <div className="step-box">
          <div className="step-header" onClick={this.changeStep.bind(this, 0)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[0].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }
    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step1">
        <div className="step-header" onClick={this.changeStep.bind(this, 0)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[0].title}</span>

          {error && this.render_alert()}
        </div>
        <div className="step-contents opened">
          <div className="step-content1">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">First Name</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="firstname"
                    defaultValue={form_items[0].value}
                  />
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Last Name</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="lastname"
                    defaultValue={form_items[1].value}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Home Phone Number</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="hphone"
                    defaultValue={form_items[2].value}
                  />
                </div>
              </div>

              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Cell Phone Number</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="cphone"
                    defaultValue={form_items[3].value}
                  />
                </div>
              </div>

              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Work Phone Number</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="wphone"
                    defaultValue={form_items[4].value}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Email Address</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="email"
                    defaultValue={form_items[5].value}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Home Address</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="address"
                    defaultValue={form_items[6].value}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">City</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="city"
                    defaultValue={form_items[7].value}
                  />
                </div>
              </div>

              <div className="col-md-2 col-xs-6">
                <div className="form-group text-left">
                  <label className="s-label4-required">State</label>
                  <select
                    className="sform-control1"
                    name="state"
                    value={form_items[9].value}
                  >
                    <option value=""></option>
                    {F_UTIL.states.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-2 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Zip Code</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="zipcode"
                    defaultValue={form_items[8].value}
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-6">
                <div className="form-group text-left">
                  <label className="s-label4-required">Country</label>

                  <select
                    className="sform-control1"
                    name="country"
                    value={form_items[10].value}
                  >
                    <option></option>
                    {F_UTIL.countries.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step2() {
    const { form_items, steps } = this.state
    var race = form_items[20].value
    var race1_or = race === '1' || race === 'Multi-Racial'
    var race1_and = (race === 0 || race !== 'Multi-Racial') && race !== 1

    var race2 = form_items[22].value
    var races2 = race2.split(',')

    console.log('Races2: ' + race2)
    for (var i = 0; i < races2.length; i++) {
      console.log(i + 'th race:' + races2[i])
    }
    const error = steps[1].error
    if (steps[1].open === false) {
      return (
        <div className="step-box">
          <div className="step-header" onClick={this.changeStep.bind(this, 1)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[1].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }

    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step2">
        <div className="step-header" onClick={this.changeStep.bind(this, 1)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[1].title}</span>
          {error && this.render_alert()}
        </div>

        <div className="step-contents">
          <div className="step-content1">
            <div className="row">
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    What would you like your donor name (alias) to be? *
                  </label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="nickname"
                    defaultValue={form_items[11].value}
                  />
                  <div className="s-label4 mt-2 opacity-60">
                    This is for your anonymity. The intended parents will refer
                    to you as your donor name
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    <br />
                    Date of Birth
                  </label>
                  <br />
                  <DatePicker
                    selected={form_items[12].value}
                    onChange={this.handleDateChange.bind(
                      this,
                      form_items[12].name,
                    )}
                    placeholderText=""
                    className="sform-control1"
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    <br />
                    Occupation
                  </label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="occupation"
                    defaultValue={form_items[13].value}
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Body Type</label>
                  <select
                    name="bodytype"
                    className="sform-control1"
                    value={form_items[14].value}
                  >
                    <option value=""></option>
                    {F_UTIL.bodytype.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Height</label>
                  <select
                    name="height"
                    className="sform-control1"
                    value={form_items[15].value}
                  >
                    {F_UTIL.height_options.map(function (obj, i) {
                      return <option value={obj.value}>{obj.title}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Weight</label>
                  <input
                    type="text"
                    className="sform-control1"
                    name="weight"
                    onKeyPress={this.changeNumber.bind(this)}
                    defaultValue={form_items[16].value}
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Eye Color</label>

                  <select
                    name="eyecolor"
                    className="sform-control1"
                    value={form_items[17].value}
                  >
                    <option value=""></option>
                    {F_UTIL.eyecolor.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Natural Hair Color
                  </label>

                  <select
                    name="haircolor"
                    className="sform-control1"
                    value={form_items[18].value}
                  >
                    <option value=""></option>
                    {F_UTIL.haircolor.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Hair Type</label>

                  <select
                    name="hairtype"
                    className="sform-control1"
                    value={form_items[19].value}
                  >
                    <option value=""></option>
                    {F_UTIL.hairtype.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Ethnicity</label>
                  <select
                    name="race2[]"
                    className="sform-control1"
                    value={races2[0]}
                  >
                    <option value=""></option>
                    {F_UTIL.races.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Your Complexion</label>
                  <select
                    className="sform-control1"
                    name="complexion"
                    value={form_items[21].value}
                  >
                    <option value=""></option>
                    {F_UTIL.complexion.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row mt__20">
              <div className="col-md-3">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Are you Multiracial?
                  </label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {race1_or ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="race"
                          value="1"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes ">
                        <input
                          type="radio"
                          name="race"
                          value="1"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {race1_and ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="race"
                          value="0"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes ">
                        <input
                          type="radio"
                          name="race"
                          value="0"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {race1_or ? (
              <div className="row mt__10">
                <div className="col-md-12 col-xs-12">
                  <div
                    className={
                      error
                        ? 'form-group text-left isError'
                        : 'form-group text-left'
                    }
                  >
                    <label className="s-label4 italic">
                      Select up to four backgorunds:
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12">
                  <div className="form-group text-left">
                    <select
                      name="race2[]"
                      className="sform-control1"
                      value={races2[1]}
                    >
                      <option value=""></option>
                      {F_UTIL.races.map(function (obj, i) {
                        return <option value={obj}>{obj}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12">
                  <div className="form-group text-left">
                    <select
                      name="race2[]"
                      className="sform-control1"
                      value={races2[2]}
                    >
                      <option value=""></option>
                      {F_UTIL.races.map(function (obj, i) {
                        return <option value={obj}>{obj}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12">
                  <div className="form-group text-left">
                    <select
                      name="race2[]"
                      className="sform-control1"
                      value={races2[3]}
                    >
                      <option value=""></option>
                      {F_UTIL.races.map(function (obj, i) {
                        return <option value={obj}>{obj}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12">
                  <div className="form-group text-left">
                    <select
                      name="race2[]"
                      className="sform-control1"
                      value={races2[4]}
                    >
                      <option value=""></option>
                      {F_UTIL.races.map(function (obj, i) {
                        return <option value={obj}>{obj}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="row mt__10">
              <div className="col-md-8 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Other Distinguishing Features
                  </label>
                  <textarea
                    className="sform-control1"
                    name="otherfeatures"
                    rows="5"
                    onChange={this.handleTextareadChange}
                    value={form_items[23].value}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step3() {
    const { form_items, steps } = this.state

    const error = steps[2].error
    if (steps[2].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 2)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[2].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }

    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step3">
        <div className="step-header" onClick={this.changeStep.bind(this, 2)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[2].title}</span>
          {error && this.render_alert()}
        </div>
        <div className="step-contents">
          <div className="step-content2">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Religion at Birth
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <select
                        name="birthreligion"
                        className="sform-control1"
                        value={form_items[24].value}
                      >
                        <option value=""></option>
                        {F_UTIL.religion.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Are you right handed or left handed?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <select
                        name="handside"
                        className="sform-control1"
                        value={form_items[25].value}
                      >
                        <option value=""></option>
                        {F_UTIL.handside.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Have you received any tattoos in the last year?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[26].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="tattoo"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="tattoo"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[26].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="tattoo"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="tattoo"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[26].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Number of tattoos and date of last tattoo
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="tattoo1"
                          defaultValue={form_items[238].value}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Have you received any piercings last year?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[27].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="piercing"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="piercing"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[27].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="piercing"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="piercing"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[27].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Number of piercings and date of last piercing
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="piercing1"
                          defaultValue={form_items[239].value}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Do you have a valid driver's license
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[28].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="drive"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="drive"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[28].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="drive"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="drive"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Do you have access to a car?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[29].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="car"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="car"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[29].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="car"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="car"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Are you registered with another agency or clinic?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[30].value === 'yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="otheragency"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="otheragency"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[30].value === 'no' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="otheragency"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="otheragency"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        {' '}
                        *Being registered with another agency or clinic will not
                        preclude your participation in our program.
                      </label>
                    </div>
                  </div>
                </div>

                {form_items[30].value === 'yes' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Name of agency(ies)/clinic(s)
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="agencyname"
                          defaultValue={form_items[240].value}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Marital Status
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <select
                        name="maritalstatus"
                        className="sform-control1"
                        value={form_items[31].value}
                      >
                        <option value="">General</option>
                        {F_UTIL.maritalstatus.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                {form_items[31].value === 'Divorced' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Number of Divorces:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="numdivorces"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[224].value}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {form_items[31].value === 'Divorced' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Date of last divorce:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <DatePicker
                          selected={form_items[225].value}
                          onChange={this.handleDateChange.bind(
                            this,
                            'divorcedates',
                          )}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {form_items[31].value === 'Married' ||
                form_items[31].value === 'Committed Relationship' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Husband/Partner's Name:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="partnername"
                          defaultValue={form_items[221].value}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {form_items[31].value === 'Married' ||
                form_items[31].value === 'Committed Relationship' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Husband/Partner's Age:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="partnerage"
                          defaultValue={form_items[222].value}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {form_items[31].value === 'Married' ||
                form_items[31].value === 'Committed Relationship' ? (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Husband/Partner's Occupation:
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <input
                          type="text"
                          className="sform-control1"
                          name="partneroccupation"
                          defaultValue={form_items[223].value}
                        ></input>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row mt__20">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Have you or your husband/partner filed for bankruptcy?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[32].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="bankruptcy"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="bankruptcy"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[32].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="bankruptcy"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="bankruptcy"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[32].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Please Explain
                        </label>
                        <textarea
                          className="sform-control1"
                          name="bankexp"
                          rows="3"
                          onChange={this.handleTextareadChange}
                          value={form_items[241].value}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Been in a psychiatric facility?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[33].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="psychfacility"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="psychfacility"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[33].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="psychfacility"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="psychfacility"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[33].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Please Explain
                        </label>
                        <textarea
                          className="sform-control1"
                          name="psychexp"
                          rows="3"
                          onChange={this.handleTextareadChange}
                          value={form_items[242].value}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Been arrested?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[34].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="arrested"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="arrested"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[34].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="arrested"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="arrested"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[34].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Please Explain
                        </label>
                        <textarea
                          className="sform-control1"
                          name="arrestedexp"
                          rows="3"
                          onChange={this.handleTextareadChange}
                          value={form_items[243].value}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Been involved in any legal cases, or any that are
                        pending?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[35].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="legalcases"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="legalcases"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[35].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="legalcases"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="legalcases"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[35].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Please Explain
                        </label>
                        <textarea
                          className="sform-control1"
                          name="legalcasesexp"
                          rows="3"
                          onChange={this.handleTextareadChange}
                          value={form_items[244].value}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Been in a substance abuse program?
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[36].value === 'Yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="subabuse"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="subabuse"
                              value="Yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[36].value === 'No' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="subabuse"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="subabuse"
                              value="No"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {form_items[36].value === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4-required">
                          Please Explain
                        </label>
                        <textarea
                          className="sform-control1"
                          name="subabuseexp"
                          rows="3"
                          onChange={this.handleTextareadChange}
                          value={form_items[245].value}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row mt__30">
                  <div className="col-md-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        How did you find Egg Donation Inc
                      </label>
                      <textarea
                        className="sform-control1"
                        name="how_find"
                        rows="2"
                        onChange={this.handleTextareadChange}
                        value={form_items[37].value}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row mt__10">
                  <div className="col-md-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        What prompted you to register?
                      </label>
                      <textarea
                        className="sform-control1"
                        name="prompt_register"
                        rows="2"
                        onChange={this.handleTextareadChange}
                        value={form_items[38].value}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1">
            <div className="row mt__50">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    please make sure to answer all pertinent questions and click
                    Yes below
                  </label>
                </div>
              )}
              <div className="col-md-7 col-xs-12">
                <div
                  className={
                    error
                      ? 'form-group text-left isError'
                      : 'form-group text-left'
                  }
                >
                  <label className="s-title5">
                    Have you answered all pertinent questions for this section?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[39].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="completedgeneral"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="completedgeneral"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[39].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="completedgeneral"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="completedgeneral"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step4() {
    const { educationlevel_items, form_items, steps } = this.state
    var _this = this
    const error = steps[3].error
    if (steps[3].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 3)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[3].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }

    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step4">
        <div className="step-header" onClick={this.changeStep.bind(this, 3)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[3].title}</span>
          {error && this.render_alert()}
        </div>
        <div className="step-contents">
          <div className="step-content1">
            <div className="row">
              {error && (
                <div className="col-md-12 text-left">
                  <label className="s-label-error">
                    Please select one of the following options at least
                  </label>
                </div>
              )}

              <div className="col-md-12 text-left">
                <label className="s-subtitle">
                  Click on each education level that you have completed and
                  provide details for each:
                </label>
              </div>

              {educationlevel_items.map(function (obj, i) {
                return (
                  <div className="col-md-4 col-xs-12">
                    <div className="s-checkbox text-left">
                      <Checkbox
                        key={i}
                        checked={obj.checked}
                        name="educationlevel[]"
                        value={obj.value}
                        onChange={_this.changeFilterEducationLevel.bind(
                          _this,
                          i,
                        )}
                      />
                      &nbsp;&nbsp;{obj.title}
                    </div>
                  </div>
                )
              })}
            </div>

            {educationlevel_items[1].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[246].title}</label>
                    <textarea
                      className="sform-control1"
                      name="highschool"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[246].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[2].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[247].title}</label>
                    <textarea
                      className="sform-control1"
                      name="college"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[247].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[3].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[248].title}</label>
                    <textarea
                      className="sform-control1"
                      name="occupationalcollege"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[248].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[4].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[249].title}</label>
                    <textarea
                      className="sform-control1"
                      name="technicalcollege"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[249].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[5].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[250].title}</label>
                    <textarea
                      className="sform-control1"
                      name="associatedegree"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[250].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[6].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[251].title}</label>
                    <textarea
                      className="sform-control1"
                      name="bachelordegree"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[251].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[7].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[252].title}</label>
                    <textarea
                      className="sform-control1"
                      name="mastersdegree"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[252].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[8].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[253].title}</label>
                    <textarea
                      className="sform-control1"
                      name="phdmddegree"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[253].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[9].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[254].title}</label>
                    <textarea
                      className="sform-control1"
                      name="doctoralprogram"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[254].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {educationlevel_items[10].checked === true ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">{form_items[255].title}</label>
                    <textarea
                      className="sform-control1"
                      name="othereducation"
                      rows="2"
                      onChange={this.handleTextareadChange}
                      value={form_items[255].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="row mt__50">
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Major</label>
                  <input
                    className="sform-control1"
                    name="major"
                    defaultValue={form_items[50].value}
                  ></input>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">SAT Score</label>
                  <input
                    className="sform-control1"
                    name="satscore"
                    defaultValue={form_items[51].value}
                  ></input>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">ACT Score</label>
                  <input
                    className="sform-control1"
                    name="actscore"
                    defaultValue={form_items[52].value}
                  ></input>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">College GPA Score</label>
                  <input
                    className="sform-control1"
                    name="collegegpa"
                    defaultValue={form_items[53].value}
                  ></input>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">IQ Score</label>
                  <input
                    className="sform-control1"
                    name="iqscore"
                    defaultValue={form_items[54].value}
                  ></input>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    What were your best subjects in school?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="bestsubjects"
                    rows="2"
                    onChange={this.handleTextareadChange}
                    value={form_items[55].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Do you have plans on furthering your education? Please give
                    details:
                  </label>
                  <textarea
                    className="sform-control1"
                    name="educationplans"
                    rows="2"
                    onChange={this.handleTextareadChange}
                    value={form_items[56].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    What are your goals in life?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="goalsinlife"
                    rows="5"
                    onChange={this.handleTextareadChange}
                    value={form_items[57].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mt__30">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    please make sure to answer all pertinent questions and click
                    Yes below
                  </label>
                </div>
              )}
              <div className="col-md-7 col-xs-12">
                <div
                  className={
                    error
                      ? 'form-group text-left isError'
                      : 'form-group text-left'
                  }
                >
                  <label className="s-title5">
                    Have you answered all pertinent questions for this section?
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[58].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="completededucation"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="completededucation"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[58].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="completededucation"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="completededucation"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step5() {
    const { form_items, std_items, steps } = this.state
    var _this = this
    const error = steps[4].error
    if (steps[4].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 4)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[4].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }

    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step5">
        <div className="step-header" onClick={this.changeStep.bind(this, 4)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[4].title}</span>
          {error && this.render_alert()}
        </div>
        <div className="step-contents">
          <div className="step-content2">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-left">
                  <label className="s-subtitle">
                    Please fill out the below to the best of your ability
                  </label>
                </div>
              </div>
            </div>

            <div className="row mt__20">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-title3">PREGNANCY HISTORY</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Number of Pregancies</label>

                  <select
                    name="pregnum"
                    className="sform-control1"
                    value={form_items[59].value}
                  >
                    <option value=""></option>
                    {F_UTIL.pregnum.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              {form_items[59].value >= 1 ? (
                <div className="col-md-6 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 text-left">
                      <label className="s-title3">Pregnancy #1</label>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Date of Birth</label>
                        <DatePicker
                          selected={form_items[60].value}
                          onChange={this.handleDateChange.bind(this, 'p1dob')}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Months trying to Conceive
                        </label>
                        <input
                          type="text"
                          name="p1conceive"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[61].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Birth Weight</label>
                        <input
                          type="text"
                          name="p1birthweight"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[62].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Outcome</label>
                        <select
                          name="p1outcome"
                          className="sform-control1"
                          value={form_items[63].value}
                        >
                          <option value="">Normal</option>
                          {F_UTIL.outcome.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[59].value >= 2 ? (
                <div className="col-md-6 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 text-left">
                      <label className="s-title3">Pregnancy #2</label>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Date of Birth</label>
                        <DatePicker
                          selected={form_items[64].value}
                          onChange={this.handleDateChange.bind(this, 'p2dob')}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Months trying to Conceive
                        </label>
                        <input
                          type="text"
                          name="p2conceive"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[65].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Birth Weight</label>
                        <input
                          type="text"
                          name="p2birthweight"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[66].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Outcome</label>

                        <select
                          name="p2outcome"
                          className="sform-control1"
                          value={form_items[67].value}
                        >
                          <option value="">Normal</option>
                          {F_UTIL.outcome.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[59].value >= 3 ? (
                <div className="col-md-6 col-xs-12">
                  <div className="row mt__20">
                    <div className="col-md-12 col-xs-12 text-left">
                      <label className="s-title3">Pregnancy #3</label>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Date of Birth</label>
                        <DatePicker
                          selected={form_items[68].value}
                          onChange={this.handleDateChange.bind(this, 'p3dob')}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Months trying to Conceive
                        </label>
                        <input
                          type="text"
                          name="p3conceive"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[69].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Birth Weight</label>
                        <input
                          type="text"
                          name="p3birthweight"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[70].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Outcome</label>
                        <select
                          name="p3outcome"
                          className="sform-control1"
                          value={form_items[71].value}
                        >
                          <option value="">Normal</option>
                          {F_UTIL.outcome.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[59].value >= 4 ? (
                <div className="col-md-6 col-xs-12">
                  <div className="row mt__20">
                    <div className="col-md-12 col-xs-12 text-left">
                      <label className="s-title3">Pregnancy #4</label>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Date of Birth</label>
                        <DatePicker
                          selected={form_items[226].value}
                          onChange={this.handleDateChange.bind(this, 'p4dob')}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Months trying to Conceive
                        </label>
                        <input
                          type="text"
                          name="p4conceive"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[227].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Birth Weight</label>
                        <input
                          type="text"
                          name="p4birthweight"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[228].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Outcome</label>
                        <select
                          name="p4outcome"
                          className="sform-control1"
                          value={form_items[229].value}
                        >
                          <option value="">Normal</option>
                          {F_UTIL.outcome.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[59].value >= 5 ? (
                <div className="col-md-6 col-xs-12">
                  <div className="row mt__20">
                    <div className="col-md-12 col-xs-12 text-left">
                      <label className="s-title3">Pregnancy #5</label>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Date of Birth</label>
                        <DatePicker
                          selected={form_items[230].value}
                          onChange={this.handleDateChange.bind(this, 'p5dob')}
                          placeholderText=""
                          className="sform-control1"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Months trying to Conceive
                        </label>
                        <input
                          type="text"
                          name="p5conceive"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[231].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Birth Weight</label>
                        <input
                          type="text"
                          name="p5birthweight"
                          className="sform-control1"
                          onKeyPress={this.changeNumber.bind(this)}
                          defaultValue={form_items[232].value}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Outcome</label>
                        <select
                          name="p5outcome"
                          className="sform-control1"
                          value={form_items[233].value}
                        >
                          <option value="">Normal</option>
                          {F_UTIL.outcome.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row mt__20">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Tell us about pregnancy complications
                  </label>

                  <textarea
                    className="sform-control1"
                    name="pregcomplication"
                    rows="5"
                    onChange={this.handleTextareadChange}
                    value={form_items[72].value}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content">
            <div className="row mt__30">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Has anyone in your family had difficulty achieving
                    pregnancy?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[73].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="difficultconception"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="difficultconception"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[73].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="difficultconception"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="difficultconception"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[73].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>
                    <textarea
                      className="sform-control1"
                      name="difficultconexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[74].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row mt__20">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Are there twins or triplets in your family?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[75].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="twinstriplets"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="twinstriplets"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[75].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="twinstriplets"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="twinstriplets"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[75].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Who is in the family?
                    </label>
                    <textarea
                      className="sform-control1"
                      name="twinstripexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[76].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="step-content1 mt__50">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-left">
                  <label className="s-title3">MEDICAL INFORMATION</label>
                </div>
              </div>
            </div>

            <div className="row mt__20">
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">OB-GYN's Name</label>
                  <input
                    type="text"
                    name="obgynname"
                    className="sform-control1"
                    defaultValue={form_items[77].value}
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">OBGYN's Phone Number</label>
                  <input
                    type="text"
                    name="obgynnumber"
                    className="sform-control1"
                    defaultValue={form_items[78].value}
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Last Visit</label>

                  <DatePicker
                    selected={form_items[79].value}
                    onChange={this.handleDateChange.bind(this, 'lastobvisit')}
                    placeholderText=""
                    className="sform-control1"
                  />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Last Visit Pap Smear</label>
                  <DatePicker
                    selected={form_items[80].value}
                    onChange={this.handleDateChange.bind(this, 'lastpapsmear')}
                    placeholderText=""
                    className="sform-control1"
                  />
                </div>
              </div>

              <div className="col-md-8 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Results</label>
                  <input
                    type="text"
                    name="papresults"
                    className="sform-control1"
                    defaultValue={form_items[81].value}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="step-content">
            <div className="row mt__20">
              <div className="col-md-7 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Has your weight changed dramatically in the last five years
                    for reasons other than pregnancy?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[82].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="changeweight"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="changeweight"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[82].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="changeweight"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="changeweight"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {form_items[82].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please explain why
                    </label>

                    <textarea
                      className="sform-control1"
                      name="weightchange"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[83].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row mt__20">
              <div className="col-md-7 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Are you using birth control?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[84].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="birthcontrol"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="birthcontrol"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[84].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="birthcontrol"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="birthcontrol"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1">
            {form_items[84].value === 'Yes' ? (
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">What method?</label>
                    <input
                      type="text"
                      name="bcmethod"
                      className="sform-control1"
                      defaultValue={form_items[85].value}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">For how long?</label>
                    <input
                      type="text"
                      name="bcmethodusage"
                      className="sform-control1"
                      defaultValue={form_items[86].value}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Any complications with this method?
                    </label>
                    <div className="btn-group" data-toggle="buttons">
                      {form_items[87].value === 'yes' ? (
                        <label className="btn btn-default btn-yes active">
                          <input
                            type="radio"
                            name="bcmethodcomplication"
                            value="yes"
                            onChange={this.handleRadioChange.bind(this)}
                            checked
                          />{' '}
                          Yes
                        </label>
                      ) : (
                        <label className="btn btn-default btn-yes">
                          <input
                            type="radio"
                            name="bcmethodcomplication"
                            value="yes"
                            onChange={this.handleRadioChange.bind(this)}
                          />{' '}
                          Yes
                        </label>
                      )}

                      {form_items[87].value === 'no' ? (
                        <label className="btn btn-default active">
                          <input
                            type="radio"
                            name="bcmethodcomplication"
                            value="no"
                            onChange={this.handleRadioChange.bind(this)}
                            checked
                          />{' '}
                          No
                        </label>
                      ) : (
                        <label className="btn btn-default">
                          <input
                            type="radio"
                            name="bcmethodcomplication"
                            value="no"
                            onChange={this.handleRadioChange.bind(this)}
                          />{' '}
                          No
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="step-content">
            <div className="row mt__20">
              <div className="col-md-7 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Do you have regular monthly menstrual cycles?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[88].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="regcycles"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="regcycles"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[88].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="regcycles"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="regcycles"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {form_items[88].value === 'No' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>

                    <textarea
                      className="sform-control1"
                      name="cycledetails"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[89].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="step-content1">
            <div className="row mt__50">
              <div className="col-md-12 col-xs-12 text-left">
                <label className="s-label4">
                  If any of your biological relatives have suffered from the
                  following, please mark the box and list their relation to you
                  in the box provided.
                </label>
              </div>
              {form_items.map(function (obj, i) {
                if (i >= 90 && i <= 118) {
                  return (
                    <div className="col-md-4 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">{obj.title}</label>
                        <input
                          type="text"
                          name={obj.name}
                          className="sform-control1"
                          placeholder="List Relatives"
                          defaultValue={obj.value}
                        />
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>

          <div className="step-content mt__50">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group text-left">
                  <label className="s-title3">MEDICAL HISTORY</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Blood Type</label>
                </div>
              </div>
              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <select
                    name="bloodtype"
                    className="sform-control1"
                    value={form_items[234].value}
                  >
                    <option value=""></option>
                    {F_UTIL.bloodtype_options.map(function (obj, i) {
                      return <option value={obj.value}>{obj.title}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Sexual Orientation</label>
                </div>
              </div>
              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <select
                    name="sexorient"
                    className="sform-control1"
                    value={form_items[235].value}
                  >
                    <option value=""></option>
                    {F_UTIL.sexorient.map(function (obj, i) {
                      return <option value={obj}>{obj}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">Do you smoke?</label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[119].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="smoker"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="smoker"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[119].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="smoker"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="smoker"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[119].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      How much per day? (in packs)
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[119].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <input
                      type="text"
                      name="smoker1"
                      className="sform-control1"
                      defaultValue={form_items[120].value}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Do you drink alcoholic beverages?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[121].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="drinkalcohol"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="drinkalcohol"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[121].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="drinkalcohol"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="drinkalcohol"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[121].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      How often? (per day/week, etc)
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[121].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <input
                      type="text"
                      className="sform-control1"
                      name="amountalcohol"
                      defaultValue={form_items[122].value}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you ever used diet pills?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[123].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="dietpills"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="dietpills"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[123].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="dietpills"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="dietpills"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[123].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">Last date used:</label>
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[123].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <DatePicker
                      selected={form_items[124].value}
                      onChange={this.handleDateChange.bind(
                        this,
                        'dietpills_lastused',
                      )}
                      placeholderText=""
                      className="sform-control1"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you ever used illicit drugs?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[125].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="druguse"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="druguse"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[125].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="druguse"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="druguse"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[125].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">Drugs used:</label>
                    <input
                      type="text"
                      name="druguse_drugname"
                      className="sform-control1"
                      defaultValue={form_items[126].value}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}

              {form_items[125].value === 'Yes' ? (
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Date last date used:
                    </label>
                    <DatePicker
                      selected={form_items[127].value}
                      onChange={this.handleDateChange.bind(
                        this,
                        'druguse_lastused',
                      )}
                      placeholderText=""
                      className="sform-control1"
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you or your partner ever had a blood transfusion?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[128].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="transfusion"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="transfusion"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[128].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="transfusion"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="transfusion"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[128].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>

                    <textarea
                      className="sform-control1"
                      name="transfusionexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[129].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you or your partner ever had any psychological
                    counseling?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[130].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="counseling"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="counseling"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[130].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="counseling"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="counseling"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[130].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>

                    <textarea
                      className="sform-control1"
                      name="counselingexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[131].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you or your partner ever been hospitalized for
                    psychiatric illness?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[132].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="psychillness"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="psychillness"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[132].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="psychillness"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="psychillness"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[132].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>

                    <textarea
                      className="sform-control1"
                      name="psychillnessexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[133].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you ever been arrested?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[134].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="arrests"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="arrests"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[134].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="arrests"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="arrests"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>

              {form_items[134].value === 'Yes' ? (
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4-required">
                      Please provide details:
                    </label>

                    <textarea
                      className="sform-control1"
                      name="arrestsexp"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[135].value}
                    ></textarea>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Have you or your husband or partner or any past sexual
                    partners ever been diagnosed with HIV or AIDS?
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[136].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="hivaids"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="hivaids"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[136].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="hivaids"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="hivaids"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt__20">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    Enter "NONE" if not applicable
                  </label>
                </div>
              )}
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Please describe any medical problems you may have/had or any
                    Genetic Carrier results:
                  </label>

                  <textarea
                    className="sform-control1"
                    name="medicalproblems"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[137].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    Enter "NONE" if not applicable
                  </label>
                </div>
              )}
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Please list any medications and dosage you are currently
                    taking:
                  </label>

                  <textarea
                    className="sform-control1"
                    name="currentmeds"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[138].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    Enter "NONE" if not applicable
                  </label>
                </div>
              )}
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Please list all other prescription medication and dosage
                    taken over the last year and reason for taking:
                  </label>

                  <textarea
                    className="sform-control1"
                    name="yearmeds"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[139].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    Enter "NONE" if not applicable
                  </label>
                </div>
              )}
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Please list any hospitalizations, surgeries, or plastic
                    surgeries you have had and the dates each event occurred
                  </label>

                  <textarea
                    className="sform-control1"
                    name="hospitalizations"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[140].value}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1 mt__20">
            <div className="row" id="std_row">
              {error && (
                <div className="col-12 text-left">
                  <label className="s-label-error">
                    Please select one of the following options
                  </label>
                </div>
              )}
              <div className="col-md-12 text-left">
                <label className="s-subtitle">
                  Have you or your husband or partner or any past sexual
                  partners ever been diagnosed with the following:
                </label>
              </div>

              {std_items.map(function (obj, i) {
                if (i === 0 && obj.value === '') {
                  return (
                    <div className="col-md-4 col-xs-12">
                      <div className="s-checkbox text-left">
                        <Checkbox
                          id="resetstds"
                          key={i}
                          checked={obj.checked}
                          name="STD[]"
                          value=""
                          onChange={_this.changeFilterSTD.bind(_this, 0)}
                        />
                        &nbsp;&nbsp;{obj.title}
                      </div>
                    </div>
                  )
                }
                return (
                  <div className="col-md-4 col-xs-12">
                    <div className="s-checkbox text-left">
                      <Checkbox
                        key={i}
                        checked={obj.checked}
                        name="STD[]"
                        value={obj.value}
                        onChange={_this.changeFilterSTD.bind(_this, i)}
                      />
                      &nbsp;&nbsp;{obj.title}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="step-content1 mt__20">
            <div className="row">
              <div className="col-md-7 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Show Medical and Genetic Traits to Recipient
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[142].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="viewable_recipient"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="viewable_recipient"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[142].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="viewable_recipient"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="viewable_recipient"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1 mt__50">
            <div className="row">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    please make sure to answer all pertinent questions and click
                    Yes below
                  </label>
                </div>
              )}
              <div className="col-md-7 col-xs-12">
                <div
                  className={
                    error
                      ? 'form-group text-left isError'
                      : 'form-group text-left'
                  }
                >
                  <label className="s-title5">
                    Have you answered all pertinent questions for this section?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[143].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="completedmedical"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="completedmedical"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[143].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="completedmedical"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="completedmedical"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_relative(item) {
    console.log(
      'Ancestry - relativeid:' + item.relative + ', name:' + item.name,
    )
    if (item.relative > 0 && item.name !== '' && item.name !== null) {
      return (
        <div className="col-md-5 col-xs-12">
          <div className="row mt__20">
            <div className="col-md-12 col-xs-12">
              <div className="form-group text-left">
                <label className="s-title3 capitalize">{item.name}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4">Date of Birth</label>
                <DatePicker
                  selected={item.age}
                  onChange={this.handleRelativeDateChange.bind(
                    this,
                    item.relative,
                  )}
                  placeholderText=""
                  className="sform-control1"
                />
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4">Occupation</label>
                <input
                  name="occupation"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  defaultValue={item.occupation}
                />
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4-required">Hair Color</label>

                <select
                  name="haircolor"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  value={item.haircolor}
                >
                  <option value=""></option>
                  {F_UTIL.haircolor.map(function (obj, i) {
                    return <option value={obj}>{obj}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4-required">Eye Color</label>

                <select
                  name="eyecolor"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  value={item.eyecolor}
                >
                  <option value=""></option>
                  {F_UTIL.eyecolor.map(function (obj, i) {
                    return <option value={obj}>{obj}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4">Height</label>

                <select
                  name="height"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  value={item.height}
                >
                  {F_UTIL.height_options.map(function (obj, i) {
                    return <option value={obj.value}>{obj.title}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4">Weight</label>
                <input
                  type="text"
                  name="weight_"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  defaultValue={item.weight_}
                />
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4-required">Health Status</label>

                <select
                  name="healthstatus"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  value={item.healthstatus}
                >
                  <option></option>
                  {F_UTIL.healthstatus.map(function (obj, i) {
                    return <option value={obj}>{obj}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label4">Deceased?</label>

                <select
                  name="deceased"
                  className="sform-control1"
                  data-relative-id={item.relative}
                  value={item.deceased}
                >
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>

            {item.deceased === 1 && (
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Cause of death</label>
                  <input
                    type="text"
                    name="cod"
                    className="sform-control1"
                    data-relative-id={item.relative}
                    defaultValue={item.cod}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  render_step6() {
    const { form_items, related_items, steps } = this.state
    var mother_relative_id = related_items[0].relative
    var father_relative_id = related_items[1].relative
    var m_grandmother_relative_id = related_items[2].relative
    var m_grandfather_relative_id = related_items[3].relative
    var p_grandmother_relative_id = related_items[4].relative
    var p_grandfather_relative_id = related_items[5].relative

    var mother = related_items[0]
    var father = related_items[1]
    var m_grandmother = related_items[2]
    var m_grandfather = related_items[3]
    var p_grandmother = related_items[4]
    var p_grandfather = related_items[5]

    var numchildren = form_items[148].value
    const error = steps[5].error
    if (steps[5].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 5)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[5].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }
    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step6">
        <div className="step-header" onClick={this.changeStep.bind(this, 5)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[5].title}</span>
          {error && this.render_alert()}
        </div>
        <div className="step-contents">
          <div className="step-content1">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group text-left">
                  <label className="s-label4-required">Were you adopted?</label>
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[236].value === 'Yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="adopted"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="adopted"
                          value="Yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[236].value === 'No' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="adopted"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="adopted"
                          value="No"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {form_items[236].value === 'Yes' ? (
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label className="s-label4">
                      Do you have information about your biological parents
                    </label>
                    <textarea
                      className="sform-control1"
                      name="adopted2"
                      rows="3"
                      onChange={this.handleTextareadChange}
                      value={form_items[237].value}
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="step-content2">
            <div className="row mt__20">
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-title3">Father's Ethnicity</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Father's Father
                      </label>
                      <select
                        name="father2"
                        className="sform-control1"
                        value={form_items[144].value}
                      >
                        <option value=""></option>
                        {F_UTIL.races.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Father's Mother
                      </label>
                      <select
                        name="father1"
                        className="sform-control1"
                        value={form_items[145].value}
                      >
                        <option value=""></option>
                        {F_UTIL.races.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-1 col-xs-12"></div>

              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group text-left">
                      <label className="s-title3">Mother's Ethnicity</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Mother's Father
                      </label>
                      <select
                        name="mother2"
                        className="sform-control1"
                        value={form_items[146].value}
                      >
                        <option value=""></option>
                        {F_UTIL.races.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4-required">
                        Mother's Mother
                      </label>
                      <select
                        name="mother1"
                        className="sform-control1"
                        value={form_items[147].value}
                      >
                        <option value=""></option>
                        {F_UTIL.races.map(function (obj, i) {
                          return <option value={obj}>{obj}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/*----------- Father Relation -------------*/}
              {this.render_relative(related_items[0])}
              {/*----------- /Father Relation -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Mother Relation -------------*/}
              {this.render_relative(related_items[1])}
              {/*----------- Mother Relation -------------*/}
            </div>

            <div className="row">
              {/*----------- Maternal Grandfather Relation -------------*/}
              {this.render_relative(related_items[2])}
              {/*----------- /Maternal Grandfather Relation -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Maternal Grandmother Relation -------------*/}
              {this.render_relative(related_items[3])}
              {/*-----------/Maternal Grandmother Relation -------------*/}
            </div>

            <div className="row">
              {/*----------- Paternal Grandfather Relation -------------*/}
              {this.render_relative(related_items[4])}
              {/*----------- /Paternal Grandfather Relation -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Paternal Grandmother Relation -------------*/}
              {this.render_relative(related_items[5])}
              {/*-----------/Paternal Grandmother Relation -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation6 -------------*/}
              {this.render_relative(related_items[6])}
              {/*----------- /Relation6 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation7 -------------*/}
              {this.render_relative(related_items[7])}
              {/*-----------/Relation7 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation8 -------------*/}
              {this.render_relative(related_items[8])}
              {/*----------- /Relation8 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation9 -------------*/}
              {this.render_relative(related_items[9])}
              {/*-----------/Relation9 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation10 -------------*/}
              {this.render_relative(related_items[10])}
              {/*----------- /Relation10 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation11 -------------*/}
              {this.render_relative(related_items[11])}
              {/*-----------/Relation11 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation12 -------------*/}
              {this.render_relative(related_items[12])}
              {/*----------- /Relation12 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation13 -------------*/}
              {this.render_relative(related_items[13])}
              {/*-----------/Relation13 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation14 -------------*/}
              {this.render_relative(related_items[14])}
              {/*----------- /Relation14 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation15 -------------*/}
              {this.render_relative(related_items[15])}
              {/*-----------/Relation15 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation16 -------------*/}
              {this.render_relative(related_items[16])}
              {/*----------- /Relation16 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation17 -------------*/}
              {this.render_relative(related_items[17])}
              {/*-----------/Relation17 -------------*/}
            </div>

            <div className="row">
              {/*----------- Relation18 -------------*/}
              {this.render_relative(related_items[18])}
              {/*----------- /Relation18 -------------*/}

              <div className="col-md-1 col-xs-12"></div>

              {/*----------- Relation19 -------------*/}
              {this.render_relative(related_items[19])}
              {/*-----------/Relation19 -------------*/}
            </div>

            {/*-----------Add new Ancestry -------------*/}
            <div className="row mt__20">
              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Sibling Relation</label>
                  <select id="new_relation" className="sform-control1 ignore">
                    <option value=""></option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="half-brother">Half-Brother</option>
                    <option value="half-sister">Half-Sister</option>
                    <option value="cousin">Cousin</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-xs-12">
                <button
                  className="btn btn-relative ignore"
                  onClick={this.onAddNewRelative.bind(this)}
                >
                  Add Sibling
                </button>
              </div>
            </div>
            {/*-----------/Add new Ancestry -------------*/}
          </div>

          <div className="step-content2">
            <div className="row mt__20">
              <div className="col-md-3 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Number of Children You Have
                  </label>
                  <select
                    name="numchildren"
                    id="numchildren"
                    className="sform-control1"
                    value={numchildren}
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12 col-xs-12">
                {numchildren > 0 ? (
                  <table className="table_children">
                    <thead>
                      <th width="20%"></th>
                      <th width="20%"></th>
                      <th width="20%"></th>
                      <th width="20%"></th>
                      <th width="20%"></th>
                    </thead>
                    <tbody>
                      {numchildren >= 1 ? (
                        <tr>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Gender</label>
                              <select
                                name="childsex1"
                                className="sform-control1"
                                value={form_items[149].value}
                              >
                                <option value=""></option>
                                <option value="Female">Girl</option>
                                <option value="Male">Boy</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Date of Birth</label>
                              <DatePicker
                                selected={form_items[150].value}
                                onChange={this.handleDateChange.bind(
                                  this,
                                  'childage1',
                                )}
                                placeholderText=""
                                className="sform-control1"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Eye Color</label>
                              <select
                                name="childeye1"
                                className="sform-control1"
                                value={form_items[151].value}
                              >
                                <option value=""></option>
                                {F_UTIL.eyecolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Hair Color</label>
                              <select
                                name="childhair1"
                                className="sform-control1"
                                value={form_items[152].value}
                              >
                                <option value=""></option>
                                {F_UTIL.haircolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Health Status</label>
                              <select
                                name="childhealth1"
                                className="sform-control1"
                                value={form_items[153].value}
                              >
                                <option value=""></option>
                                {F_UTIL.healthstatus.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                      {numchildren >= 2 ? (
                        <tr>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Gender</label>
                              <select
                                name="childsex2"
                                className="sform-control1"
                                value={form_items[154].value}
                              >
                                <option value=""></option>
                                <option value="Female">Girl</option>
                                <option value="Male">Boy</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Date of Birth</label>
                              <DatePicker
                                selected={form_items[155].value}
                                onChange={this.handleDateChange.bind(
                                  this,
                                  'childage2',
                                )}
                                placeholderText=""
                                className="sform-control1"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Eye Color</label>
                              <select
                                name="childeye2"
                                className="sform-control1"
                                value={form_items[156].value}
                              >
                                <option value=""></option>
                                {F_UTIL.eyecolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Hair Color</label>
                              <select
                                name="childhair2"
                                className="sform-control1"
                                value={form_items[157].value}
                              >
                                <option value=""></option>
                                {F_UTIL.haircolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Health Status</label>
                              <select
                                name="childhealth2"
                                className="sform-control1"
                                value={form_items[158].value}
                              >
                                <option value=""></option>
                                {F_UTIL.healthstatus.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                      {numchildren >= 3 ? (
                        <tr>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Gender</label>
                              <select
                                name="childsex3"
                                className="sform-control1"
                                value={form_items[159].value}
                              >
                                <option value=""></option>
                                <option value="Female">Girl</option>
                                <option value="Male">Boy</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Date of Birth</label>
                              <DatePicker
                                selected={form_items[160].value}
                                onChange={this.handleDateChange.bind(
                                  this,
                                  'childage3',
                                )}
                                placeholderText=""
                                className="sform-control1"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Eye Color</label>
                              <select
                                name="childeye3"
                                className="sform-control1"
                                value={form_items[161].value}
                              >
                                <option value=""></option>
                                {F_UTIL.eyecolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Hair Color</label>
                              <select
                                name="childhair3"
                                className="sform-control1"
                                value={form_items[162].value}
                              >
                                <option value=""></option>
                                {F_UTIL.haircolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Health Status</label>
                              <select
                                name="childhealth3"
                                className="sform-control1"
                                value={form_items[163].value}
                              >
                                <option value=""></option>
                                {F_UTIL.healthstatus.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}

                      {numchildren >= 4 ? (
                        <tr>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Gender</label>
                              <select
                                name="childsex4"
                                className="sform-control1"
                                value={form_items[164].value}
                              >
                                <option value=""></option>
                                <option value="Female">Girl</option>
                                <option value="Male">Boy</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Date of Birth</label>
                              <DatePicker
                                selected={form_items[165].value}
                                onChange={this.handleDateChange.bind(
                                  this,
                                  'childage4',
                                )}
                                placeholderText=""
                                className="sform-control1"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Eye Color</label>
                              <select
                                name="childeye4"
                                className="sform-control1"
                                value={form_items[166].value}
                              >
                                <option value=""></option>
                                {F_UTIL.eyecolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Hair Color</label>
                              <select
                                name="childhair4"
                                className="sform-control1"
                                value={form_items[167].value}
                              >
                                <option value=""></option>
                                {F_UTIL.haircolor.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="form-group text-left">
                              <label className="s-label4">Health Status</label>
                              <select
                                name="childhealth4"
                                className="sform-control1"
                                value={form_items[168].value}
                              >
                                <option value=""></option>
                                {F_UTIL.healthstatus.map(function (obj, i) {
                                  return <option value={obj}>{obj}</option>
                                })}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}
                    </tbody>
                  </table>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className="step-content1 mt__50">
            <div className="row">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    please make sure to answer all pertinent questions and click
                    Yes below
                  </label>
                </div>
              )}
              <div className="col-md-7 col-xs-12">
                <div
                  className={
                    error
                      ? 'form-group text-left isError'
                      : 'form-group text-left'
                  }
                >
                  <label className="s-title5">
                    Have you answered all pertinent questions for this section?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[169].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="completedgenetic"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="completedgenetic"
                          onChange={this.handleRadioChange.bind(this)}
                          value="yes"
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[169].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="completedgenetic"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="completedgenetic"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step7() {
    const { form_items, willworkwith, steps } = this.state
    var _this = this
    const error = steps[6].error
    if (steps[6].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 6)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[6].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }

    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step7">
        <div className="step-header" onClick={this.changeStep.bind(this, 6)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[6].title}</span>
          {error && this.render_alert()}
        </div>
        <div className="step-contents">
          <div className="step-content-full">
            <div className="row">
              <div className="col-md-4">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Please describe your personality and character:
                  </label>
                  <textarea
                    className="sform-control1"
                    name="personality"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[170].value}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    What are your hobbies, interests and talents?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="hobbies"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[171].value}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    What is your philosophy on life?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="philosophy"
                    rows="3"
                    onChange={this.handleTextareadChange}
                    value={form_items[172].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Do you play a musical instrument?
                      </label>

                      <select
                        className="sform-control1"
                        name="instrument"
                        value={form_items[173].value}
                      >
                        <option value=""></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  {form_items[173].value === 'yes' ? (
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Which one(s):</label>
                        <input
                          type="text"
                          name="instrument1"
                          className="sform-control1"
                          defaultValue={form_items[174].value}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Do you have any artistic talents?
                      </label>
                      <select
                        name="arttalent"
                        className="sform-control1"
                        value={form_items[175].value}
                      >
                        <option value=""></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  {form_items[175].value === 'yes' ? (
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Which one(s):</label>
                        <input
                          type="text"
                          name="arttalentexp"
                          className="sform-control1"
                          defaultValue={form_items[176].value}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Do you speak any other languages?
                      </label>
                      <select
                        name="otherlang"
                        className="sform-control1"
                        value={form_items[177].value}
                      >
                        <option value=""></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  {form_items[177].value === 'yes' ? (
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Which one(s):</label>
                        <input
                          type="text"
                          name="otherlangexp"
                          className="sform-control1"
                          defaultValue={form_items[178].value}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Do you have any particular athletic abilities?
                      </label>
                      <select
                        name="athletic"
                        className="sform-control1"
                        value={form_items[179].value}
                      >
                        <option value=""></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  {form_items[179].value === 'yes' ? (
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Which one(s):</label>
                        <input
                          type="text"
                          name="athleticexp"
                          className="sform-control1"
                          defaultValue={form_items[180].value}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="step-content2">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-subtitle">
                    Please tell us your favorite:
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Food</label>
                  <input
                    name="favfood"
                    className="sform-control1"
                    defaultValue={form_items[181].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Color</label>
                  <input
                    name="favcolor"
                    className="sform-control1"
                    defaultValue={form_items[182].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Season</label>
                  <input
                    name="favseason"
                    className="sform-control1"
                    defaultValue={form_items[183].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Holiday</label>
                  <input
                    name="favholiday"
                    className="sform-control1"
                    defaultValue={form_items[184].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Sport(s)</label>
                  <input
                    name="favsports"
                    className="sform-control1"
                    defaultValue={form_items[185].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">School subject</label>
                  <input
                    name="favsubject"
                    className="sform-control1"
                    defaultValue={form_items[186].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Book</label>
                  <input
                    name="favbook"
                    className="sform-control1"
                    defaultValue={form_items[187].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Song</label>
                  <input
                    name="favsong"
                    className="sform-control1"
                    defaultValue={form_items[188].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">TV program</label>
                  <input
                    name="favtv"
                    className="sform-control1"
                    defaultValue={form_items[189].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Singer/Group</label>
                  <input
                    name="favsinger"
                    className="sform-control1"
                    defaultValue={form_items[190].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Movies</label>
                  <input
                    name="favmovie"
                    className="sform-control1"
                    defaultValue={form_items[191].value}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">Pet</label>
                  <input
                    name="favhobbie"
                    className="sform-control1"
                    defaultValue={form_items[192].value}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="step-content-full">
            <div className="row mt__30">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-subtitle">
                    Please tell us a little bit more about yourself:
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    Why do you want to be an egg donor?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="whydonate"
                    onChange={this.handleTextareadChange}
                    value={form_items[193].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">What I value most:</label>
                  <textarea
                    className="sform-control1"
                    name="valuemost"
                    onChange={this.handleTextareadChange}
                    value={form_items[194].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">I am proud of:</label>
                  <textarea
                    className="sform-control1"
                    name="proudof"
                    onChange={this.handleTextareadChange}
                    value={form_items[195].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">I believe that:</label>
                  <textarea
                    className="sform-control1"
                    name="believe"
                    onChange={this.handleTextareadChange}
                    value={form_items[196].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">I dislike:</label>
                  <textarea
                    className="sform-control1"
                    name="dislike"
                    onChange={this.handleTextareadChange}
                    value={form_items[197].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">My immediate goals are:</label>
                  <textarea
                    className="sform-control1"
                    name="immediategoals"
                    onChange={this.handleTextareadChange}
                    value={form_items[198].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">My future plans are:</label>
                  <textarea
                    className="sform-control1"
                    name="futureplans"
                    onChange={this.handleTextareadChange}
                    value={form_items[199].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    What is your most memorable childhood experience?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="childhoodexp"
                    onChange={this.handleTextareadChange}
                    value={form_items[200].value}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    If you won the lottery what would you do?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="lottery"
                    onChange={this.handleTextareadChange}
                    value={form_items[201].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-subtitle">
                    Please mark any or all below that you are willing to work
                    with:
                  </label>
                </div>
              </div>

              {error && (
                <div className="col-md-12 text-left">
                  <label className="s-label-error">
                    Please select one of the following options at least
                  </label>
                </div>
              )}

              {willworkwith.map(function (obj, i) {
                return (
                  <div className="col-md-2 col-xs-12">
                    <div className="s-checkbox text-left">
                      <Checkbox
                        key={i}
                        checked={obj.checked}
                        name="willworkwith[]"
                        value={obj.value}
                        onChange={_this.changeWillingworkWith.bind(_this, i)}
                      />
                      &nbsp;&nbsp;{obj.title}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="step-content2">
            <div className="row mt__20">
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    What qualities would you consider most important in choosing
                    a Recipient Couple?
                  </label>

                  <textarea
                    className="sform-control1"
                    name="qualities"
                    onChange={this.handleTextareadChange}
                    value={form_items[203].value}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Would you be open to speaking, skyping or meeting with the
                    intended parents if they requested to. Please explain why:
                  </label>
                  <textarea
                    className="sform-control1"
                    name="contactcouple"
                    onChange={this.handleTextareadChange}
                    value={form_items[204].value}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Are you open to having a relationship with your couple after
                    birth?
                  </label>

                  <textarea
                    className="sform-control1"
                    name="relationshipbirth"
                    onChange={this.handleTextareadChange}
                    value={form_items[205].value}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Have you ever placed a child for adoption
                      </label>
                      <select
                        name="childadopt"
                        className="sform-control1"
                        value={form_items[206].value}
                      >
                        <option value=""></option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  {form_items[206].value === 'yes' ? (
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Please Explain:</label>

                        <textarea
                          className="sform-control1"
                          name="childadoptexp"
                          onChange={this.handleTextareadChange}
                          value={form_items[207].value}
                        ></textarea>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    How would you respond if the child wanted to see you at some
                    time later in his or her life?
                  </label>
                  <textarea
                    className="sform-control1"
                    name="respondtochild"
                    onChange={this.handleTextareadChange}
                    value={form_items[208].value}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    If the recipient is more comfortable with an anonymous
                    donation would you still be willing to work with them?
                  </label>
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[209].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="anondonation"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="anondonation"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[209].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="anondonation"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="anondonation"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content-full">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-subtitle">
                    What kind of support do you expect from the following
                    people:
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-xs-12">
                <div className="row">
                  <div className="col-md-4 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">Friends/co-workers</label>
                      <textarea
                        className="sform-control1"
                        name="supportfriends"
                        rows="3"
                        onChange={this.handleTextareadChange}
                        value={form_items[210].value}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">Your parents</label>
                      <textarea
                        className="sform-control1"
                        name="supportparents"
                        rows="3"
                        onChange={this.handleTextareadChange}
                        value={form_items[211].value}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">Your husband/partner</label>
                      <textarea
                        className="sform-control1"
                        name="supportpartner"
                        rows="3"
                        onChange={this.handleTextareadChange}
                        value={form_items[212].value}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-xs-12" style={{ display: 'none' }}>
                <div className="form-group text-left">
                  <label className="s-label4">
                    Do you and your partner understand that, unless you have had
                    a tubal or your partner has had a vasectomy, you must agree
                    to abstain from sexual activity while undergoing medical
                    treatment and participating in this program?
                  </label>
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[213].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="nosex"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="nosex"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[213].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="nosex"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="nosex"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-9 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4-required">
                    What message would you like to pass on to the Recipient
                    Couple?{' '}
                  </label>
                  <textarea
                    className="sform-control1"
                    name="messageparents"
                    onChange={this.handleTextareadChange}
                    value={form_items[214].value}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1 mt__50">
            <div className="row">
              {error && (
                <div className="col-12">
                  <label className="s-label-error">
                    please make sure to answer all pertinent questions and click
                    Yes below
                  </label>
                </div>
              )}
              <div className="col-md-7 col-xs-12">
                <div
                  className={
                    error
                      ? 'form-group text-left isError'
                      : 'form-group text-left'
                  }
                >
                  <label className="s-title5">
                    Have you answered all pertinent questions for this section?
                  </label>
                </div>
              </div>

              <div className="col-md-5 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[215].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="completedtraits"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="completedtraits"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[215].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="completedtraits"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="completedtraits"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step8() {
    const {
      form_items,
      cycledate,
      thaw_date,
      steps,
      frozenCycles,
      freshCycles,
    } = this.state

    const error = steps[7].error
    if (steps[7].open === false) {
      return (
        <div className={error ? 'step-box error' : 'step-box'}>
          <div className="step-header" onClick={this.changeStep.bind(this, 7)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[7].title}</span>
            {error && this.render_alert()}
          </div>
        </div>
      )
    }
    return (
      <div className={error ? 'step-box error' : 'step-box'} id="step8">
        <div className="step-header" onClick={this.changeStep.bind(this, 7)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[7].title}</span>
          {error && this.render_alert()}
        </div>

        <div className="step-contents">
          <div className="step-content2">
            <div className="row">
              {/* Fresh Cycles */}
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-title3">FRESH CYCLES</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {error && form_items[216].value === '' && (
                    <div className="col-12">
                      <label className="s-label-error">
                        please make sure to click Yes or No below
                      </label>
                    </div>
                  )}
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Have you donated eggs before?
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[216].value === 'yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="donatedbefore"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="donatedbefore"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[216].value === 'no' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="donatedbefore"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="donatedbefore"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="new_cycle">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Retrieval Date</label>

                        <DatePicker
                          selected={cycledate}
                          onChange={this.handleDateChange.bind(
                            this,
                            'cycledate',
                          )}
                          placeholderText=""
                          className="sform-control1 ignore"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Doctor</label>
                        <input
                          type="text"
                          name="cycles[doctor]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Fertility Clinic</label>
                        <input
                          type="text"
                          name="cycles[fertilityclinic]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Donor Agency</label>
                        <input
                          type="text"
                          name="cycles[agency]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Pregnancy Result</label>
                        <select
                          name="cycles[pregnancyresult]"
                          className="sform-control1 ignore"
                        >
                          <option value=""></option>
                          {F_UTIL.pregnancy_result.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of eggs</label>
                        <input
                          type="text"
                          name="cycles[eggs]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of embryos</label>
                        <input
                          type="text"
                          name="cycles[embryos]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of frozen</label>
                        <input
                          type="text"
                          name="cycles[frozen]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Number of transferred
                        </label>
                        <input
                          type="text"
                          name="cycles[transferred]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of blastocyst</label>
                        <input
                          type="text"
                          name="cycles[blastocysts]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Notes</label>
                        <textarea
                          className="sform-control1 ignore"
                          name="cycles[pregnancynotes]"
                          onChange={this.handleTextareadChange}
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row mt__10">
                    <div className="col-md-12 col-xs-12">
                      <button
                        className="btn btn-upload ignore"
                        onClick={this.onClickInsertCycle.bind(this)}
                      >
                        Add Cycle
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mt__30">
                  <div className="col-xs-12 col-md-12 table-responsive">
                    <table className="table table-bordered table-sm mt-1 table-cycle">
                      <thead className="tbl-header">
                        <tr className="">
                          <th className="text-left">Cycle Date</th>
                          <th className="text-left">Eggs</th>
                          <th className="text-left">Embryos</th>
                          <th className="text-left">Result</th>
                        </tr>
                      </thead>
                      <tbody className="table_image">
                        {freshCycles === false ? (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No Data
                            </td>
                          </tr>
                        ) : (
                          freshCycles.map(function (obj, i) {
                            return (
                              <tr>
                                <td className="text-center">{obj.cycledate}</td>
                                <td className="text-center">{obj.eggs}</td>
                                <td className="text-center">{obj.embryos}</td>
                                <td>{obj.pregnancyresult}</td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Fresh Cycles End */}

              <div className="col-md-2 col-xs-12"></div>

              {/* Frozen Cycles */}
              <div className="col-md-5 col-xs-12">
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-title3">FROZEN CYCLES</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {error && form_items[217].value === '' && (
                    <div className="col-12">
                      <label className="s-label-error">
                        please make sure to click Yes or No below
                      </label>
                    </div>
                  )}
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <label className="s-label4">
                        Have you donated frozen eggs before?
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <div className="form-group text-left">
                      <div className="btn-group" data-toggle="buttons">
                        {form_items[217].value === 'yes' ? (
                          <label className="btn btn-default btn-yes active">
                            <input
                              type="radio"
                              name="donatedfrozenbefore"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            Yes
                          </label>
                        ) : (
                          <label className="btn btn-default btn-yes">
                            <input
                              type="radio"
                              name="donatedfrozenbefore"
                              value="yes"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            Yes
                          </label>
                        )}

                        {form_items[217].value === 'no' ? (
                          <label className="btn btn-default active">
                            <input
                              type="radio"
                              name="donatedfrozenbefore"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                              checked
                            />{' '}
                            No
                          </label>
                        ) : (
                          <label className="btn btn-default">
                            <input
                              type="radio"
                              name="donatedfrozenbefore"
                              value="no"
                              onChange={this.handleRadioChange.bind(this)}
                            />{' '}
                            No
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div id="new_frozen_cycle">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Doctor</label>
                        <input
                          type="text"
                          name="frozen_cycles[doctor]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Fertility Clinic</label>
                        <input
                          type="text"
                          name="frozen_cycles[fertility_clinic]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Donor Agency</label>
                        <input
                          type="text"
                          name="frozen_cycles[agency]"
                          className="sform-control1 ignore"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Pregnancy Result</label>
                        <select
                          name="frozen_cycles[pregnancy_result]"
                          className="sform-control1 ignore"
                        >
                          <option value=""></option>
                          {F_UTIL.pregnancy_result.map(function (obj, i) {
                            return <option value={obj}>{obj}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of Embryos</label>
                        <input
                          type="text"
                          name="frozen_cycles[no_embryos]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Number of Eggs Thawed
                        </label>
                        <input
                          type="text"
                          name="frozen_cycles[no_eggs_thawed]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Number of Eggs Fertilized
                        </label>
                        <input
                          type="text"
                          name="frozen_cycles[no_eggs_fertilized]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Thaw Date</label>
                        <DatePicker
                          selected={thaw_date}
                          onChange={this.handleDateChange.bind(
                            this,
                            'thaw_date',
                          )}
                          placeholderText=""
                          className="sform-control1 ignore"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">
                          Number of Transferred
                        </label>
                        <input
                          type="text"
                          name="frozen_cycles[transferred]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Number of Blasts</label>
                        <input
                          type="text"
                          name="frozen_cycles[no_blasts]"
                          className="sform-control1 ignore"
                          onKeyPress={this.changeNumber.bind(this)}
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <div className="form-group text-left">
                        <label className="s-label4">Notes</label>
                        <textarea
                          className="sform-control1 ignore"
                          name="frozen_cycles[notes]"
                          onChange={this.handleTextareadChange}
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row mt__10">
                    <div className="col-md-12 col-xs-12">
                      <button
                        className="btn btn-upload ignore"
                        onClick={this.onClickInsertFrozenCycle.bind(this)}
                      >
                        Add Cycle
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row mt__30">
                  <div className="col-xs-12 col-md-12 table-responsive">
                    <table className="table table-bordered table-sm mt-1 table-cycle">
                      <thead className="tbl-header">
                        <tr className="">
                          <th className="text-left">Cycle Date</th>
                          <th className="text-left">Eggs</th>
                          <th className="text-left">Frozen</th>
                          <th className="text-left">Available</th>
                        </tr>
                      </thead>
                      <tbody className="table_image">
                        {frozenCycles === false ? (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No Data
                            </td>
                          </tr>
                        ) : (
                          frozenCycles.map(function (obj, i) {
                            return (
                              <tr>
                                <td className="text-center">{obj.thaw_date}</td>
                                <td className="text-center">
                                  {obj.no_eggs_thawed}
                                </td>
                                <td className="text-center">
                                  {obj.pregnancy_result}
                                </td>
                                <td></td>
                              </tr>
                            )
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Frozen Cycles End */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_step9() {
    const { uploading, uploadingPrgress, photos, refreshImages, steps } =
      this.state
    var _this = this
    const error = steps[8].error
    if (steps[8].open === false) {
      return (
        <div className="step-box">
          <form id="photo_form">
            <div
              className="step-header"
              onClick={this.changeStep.bind(this, 8)}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <span>{steps[8].title}</span>
              {error && this.render_alert()}
            </div>
          </form>
        </div>
      )
    }

    return (
      <div className="step-box" id="step9">
        <form id="photo_form">
          <div className="step-header" onClick={this.changeStep.bind(this, 8)}>
            <i className="fa fa-minus" aria-hidden="true"></i>
            <span className="active">{steps[8].title}</span>
            {error && this.render_alert()}
          </div>
          <div className="step-contents">
            <div className="step-content2">
              <div className="row mt__20">
                <div className="col-md-12 col-xs-12 text-left">
                  <label className="s-title3">To Add Pictures:</label>
                  <br />
                  <label className="s-label4">
                    Please click “UPLOAD”, select the file you would like to
                    upload, click "Open" then "Add Picture".{' '}
                  </label>
                  <label className="s-label4">
                    Upload as many photos as you can and a variety of photos.
                    Your photos will invite recipients to look at the rest of
                    your profile. Please include:
                  </label>
                  <br />
                  <ul>
                    <li>Current photos</li>
                    <li>Baby and childhood photos</li>
                    <li>Photos of your profile and close-ups of your face</li>
                    <li>Full body photos</li>
                    <li>Pictures with your immediate family</li>
                    <li>
                      Your most attractive, friendly, smiling images (no
                      “selfies” please)
                    </li>
                  </ul>
                  <br />
                  <label className="s-label4">
                    If you have any other questions about optimizing your
                    profile, you are welcome to contact our Donor Coordinator.
                    We look forward to reading about your radiant personalities,
                    seeing your beautiful, smiling faces and getting you matched
                    with one of our recipients!
                  </label>
                </div>

                <div className="col-md-12 col-xs-12 text-left mt__10">
                  <input
                    type="file"
                    accept="image/*"
                    id="file"
                    onChange={this.fileSelectedHandler}
                    className="ignore"
                    multiple
                    ref={(fileInput) => (this.fileInput = fileInput)}
                    style={{ display: 'none' }}
                  />

                  <div
                    onClick={() => this.fileInput.click()}
                    className="btn btn-upload ignore"
                  >
                    Upload
                  </div>
                </div>

                <div className="col-md-6 col-xs-12 text-left mt__10">
                  {uploading ? (
                    <Progress percent={this.state.uploadingPrgress} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-right mt__10">
                    <a
                      className="btn-photo-remove"
                      onClick={_this.cancelUpload.bind(_this)}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {refreshImages ? (
              <div className="loading_donor mt__50"></div>
            ) : (
              <div className="step-content1 mt__50">
                {photos.map(function (obj, i) {
                  var img_src =
                    process.env.REACT_APP_IMAGE_URL +
                    '/images/donors2/' +
                    _this.id +
                    '/' +
                    obj.id
                  return (
                    <div className="row mt__30">
                      <div className="col-md-4 col-xs-12">
                        <LazyLoadImage
                          key={obj.id}
                          wrapperClassName="upload-img-box"
                          className="full-image"
                          effect="black-and-white"
                          alt="Featured Photo"
                          src={img_src}
                        />
                      </div>

                      <div className="col-md-1"></div>

                      <div className="col-md-7 col-xs-12">
                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div className="form-group text-left">
                              <label className="s-label4">Caption</label>
                              <input
                                type="text"
                                name="caption"
                                className="sform-control1"
                                data-photo_id={obj.id}
                                value={obj.caption}
                                onChange={_this.onChangePhotoCaption.bind(
                                  _this,
                                  obj.id,
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="s-checkbox text-left">
                              <Checkbox
                                key={i}
                                data-photo_id={obj.id}
                                checked={obj.checked}
                                name="default"
                                onChange={_this.changeFilterPhoto.bind(
                                  _this,
                                  i,
                                )}
                                value={obj.default}
                              />
                              &nbsp;&nbsp;Profile Picture
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group text-right mt__10">
                              <a
                                className="btn-photo-remove"
                                onClick={_this.removePhoto.bind(_this, obj.id)}
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }

  render_step10() {
    const { form_items, steps } = this.state

    if (steps[9].open === false) {
      return (
        <div className="step-box">
          <div className="step-header" onClick={this.changeStep.bind(this, 9)}>
            <i className="fa fa-plus" aria-hidden="true"></i>
            <span>{steps[9].title}</span>
          </div>
        </div>
      )
    }

    return (
      <div className="step-box" id="step10">
        <div className="step-header" onClick={this.changeStep.bind(this, 9)}>
          <i className="fa fa-minus" aria-hidden="true"></i>
          <span className="active">{steps[9].title}</span>
        </div>
        <div className="step-contents">
          <div className="step-content2">
            <div className="row mt__20">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Before we can consider your application for approval, you
                    must send us the following documents:
                  </label>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              <div className="col-md-12 col-xs-12">
                <ul className="ul_doc">
                  <li>
                    <a
                      href={`${process.env.REACT_APP_IMAGE_URL}/asset/pdf/HIPAA-Notice-of-Privacy-Practices.pdf`}
                      target="_blank"
                      className="pdf"
                    >
                      HIPAA Privacy Regulations Consent Form
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${process.env.REACT_APP_IMAGE_URL}/asset/pdf/EDI_Single_Agreement.pdf`}
                      target="_blank"
                      className="pdf"
                    >
                      Egg Donor Agreement and Release (if you are single and
                      unmarried)
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${process.env.REACT_APP_IMAGE_URL}/asset/pdf/EDI_Married_Agreement.pdf`}
                      target="_blank"
                      className="pdf"
                    >
                      Egg Donor Agreement and Release (if you are married or in
                      a committed relationship)
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${process.env.REACT_APP_IMAGE_URL}/asset/pdf/Declaration-for-Disclosure-of-Donor-Identity.pdf`}
                      target="_blank"
                      className="pdf"
                    >
                      Declaration for Donor Disclosure Identity
                    </a>
                  </li>

                  <li>Copy of Driver's License</li>
                </ul>
              </div>
            </div>

            <div className="row mt__10">
              <div className="col-md-12 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-label4">
                    Please send signed forms and clear photo of ID to
                    alyson@eggdonor.com
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="step-content1">
            <div className="row mt__50">
              <div className="col-md-8 col-xs-12">
                <div className="form-group text-left">
                  <label className="s-title5">
                    I agree that I have completed and sent the required items
                    for application approval.
                  </label>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="form-group text-left">
                  <div className="btn-group" data-toggle="buttons">
                    {form_items[220].value === 'yes' ? (
                      <label className="btn btn-default btn-yes active">
                        <input
                          type="radio"
                          name="agreement"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        Yes
                      </label>
                    ) : (
                      <label className="btn btn-default btn-yes">
                        <input
                          type="radio"
                          name="agreement"
                          value="yes"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        Yes
                      </label>
                    )}

                    {form_items[220].value === 'no' ? (
                      <label className="btn btn-default active">
                        <input
                          type="radio"
                          name="agreement"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                          checked
                        />{' '}
                        No
                      </label>
                    ) : (
                      <label className="btn btn-default">
                        <input
                          type="radio"
                          name="agreement"
                          value="no"
                          onChange={this.handleRadioChange.bind(this)}
                        />{' '}
                        No
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render_steps() {
    const steps = [...this.state.steps]
    const isError = _.filter(steps, { error: true }).length > 0

    return (
      <div className="step-container">
        <form id="donor_form">
          {this.render_step1()}
          {this.render_step2()}
          {this.render_step3()}
          {this.render_step4()}
          {this.render_step5()}
          {this.render_step6()}
          {this.render_step7()}
          {this.render_step8()}
          {this.render_step9()}
          {this.render_step10()}

          <div className="step-control">
            <label class="s-subtitle">
              Please click submit once all required information has been filled
              out.
            </label>
            <div className="step-control-buttons">
              <button className="btn" onClick={this.handleSubmit.bind(this)}>
                Submit
              </button>
              <button className="btn" onClick={this.handleSave.bind(this)}>
                Save and Close
              </button>
            </div>

            {isError && (
              <div className="step-control-message">
                <label class="s-subtitle error">
                  Please fill out all required fields above
                </label>
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {
      form_items,
      related_items,
      educationlevel_items,
      willworkwith,
      photos,
    } = this.state
    //check step1
    console.log('handleSubmit')
    const steps = [...this.state.steps]
    const _steps = _.map(steps, (item) => {
      if (item.step === 1) {
        const error =
          form_items[0].value === '' ||
          form_items[1].value === '' ||
          form_items[2].value === '' ||
          form_items[5].value === '' ||
          form_items[6].value === '' ||
          form_items[7].value === '' ||
          form_items[8].value === '' ||
          form_items[9].value === '' ||
          form_items[10].value === ''

        return { ...item, error }
      } else if (item.step === 2) {
        const error =
          form_items[12].value === '' ||
          form_items[13].value === '' ||
          form_items[14].value === '' ||
          form_items[15].value === '' ||
          form_items[16].value === '' ||
          form_items[17].value === '' ||
          form_items[18].value === '' ||
          form_items[19].value === '' ||
          form_items[20].value === '' ||
          form_items[21].value === '' ||
          form_items[22].value === ''

        return { ...item, error }
      } else if (item.step === 3) {
        const error =
          form_items[24].value === '' ||
          form_items[25].value === '' ||
          form_items[26].value === '' ||
          form_items[27].value === '' ||
          form_items[28].value === '' ||
          form_items[29].value === '' ||
          form_items[30].value === '' ||
          form_items[31].value === '' ||
          form_items[32].value === '' ||
          form_items[33].value === '' ||
          form_items[34].value === '' ||
          form_items[35].value === '' ||
          form_items[36].value === '' ||
          form_items[37].value === '' ||
          form_items[38].value === '' ||
          form_items[39].value !== 'yes'

        return { ...item, error: error }
      } else if (item.step === 4) {
        const selItems = _.filter(educationlevel_items, { checked: true })
        const item_error =
          selItems.length === 0 || form_items[58].value !== 'yes'
        return { ...item, error: item_error }
      } else if (item.step === 5) {
        const item_error =
          form_items[73].value === '' ||
          form_items[75].value === '' ||
          form_items[82].value === '' ||
          form_items[84].value === '' ||
          form_items[88].value === '' ||
          form_items[119].value === '' ||
          form_items[121].value === '' ||
          form_items[123].value === '' ||
          form_items[125].value === '' ||
          form_items[128].value === '' ||
          form_items[130].value === '' ||
          form_items[132].value === '' ||
          form_items[134].value === '' ||
          form_items[136].value === '' ||
          form_items[137].value === '' ||
          form_items[138].value === '' ||
          form_items[139].value === '' ||
          form_items[140].value === '' ||
          form_items[143].value !== 'yes'

        return { ...item, error: item_error }
      } else if (item.step === 6) {
        let related_error = false
        for (let i = 0; i < related_items.length; i++) {
          const rItem = related_items[i]
          if (rItem.relative > 0 && rItem.name !== '' && rItem.name !== null) {
            if (
              rItem.haircolor === '' ||
              rItem.eyecolor === '' ||
              rItem.healthstatus === ''
            ) {
              related_error = true
              break
            }
          }
        }

        const item_error =
          form_items[236].value === '' ||
          form_items[144].value === '' ||
          form_items[146].value === '' ||
          form_items[147].value === '' ||
          form_items[169].value !== 'yes'

        return { ...item, error: related_error || item_error }
      } else if (item.step === 7) {
        const selItems = _.filter(willworkwith, { checked: true })
        const item_error =
          form_items[170].value === '' ||
          form_items[171].value === '' ||
          form_items[172].value === '' ||
          form_items[193].value === '' ||
          form_items[214].value === '' ||
          selItems.length === 0 ||
          form_items[215].value !== 'yes'
        return { ...item, error: item_error }
      } else if (item.step === 8) {
        const completed =
          ((form_items[216].value === 'yes' ||
            form_items[216].value === 'no') &&
            form_items[217].value === 'yes') ||
          form_items[217].value === 'no'
        return { ...item, error: !completed }
      } else if (item.step === 9) {
        const completed = photos.length > 0
        return { ...item, error: !completed }
      }

      return { ...item }
    })

    this.setState({ steps: [..._steps] })
    const isError = _.filter(_steps, { error: true }).length > 0
    if (!isError) this.postSubmitReq()
  }

  handleSave = (e) => {
    e.preventDefault()

    var url = global.api_url + 'login/logout'
    window.localStorage.clear()

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')
        window.location = '/login'
      }.bind(this),
    })
  }

  render_content() {
    return (
      <div className="content">
        <div className="container">
          {this.render_header()}
          {this.render_steps()}
          {this.renderModal()}
        </div>
      </div>
    )
  }

  declineAgreement() {
    F_UTIL.goto_login()
  }

  acceptAgreement() {
    var consent_items = $('#consent_items :checkbox')
    var checked = $('#consent_items :checked')

    consent_items.each(function (i, v) {
      if (!$(v).is(':checked')) {
        $(v)
          .parents('.s-checkbox1')
          .addClass('has-error')
          .removeClass('has-success')
      } else {
        $(v)
          .parents('.s-checkbox1')
          .addClass('has-success')
          .removeClass('has-error')
      }
    })

    if (checked.length === 5) {
      var url = global.api_url + 'donor/update_consent/' + this.id
      console.log('Call API:', url)

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        cache: false,
        crossDomain: true,
        success: function (response) {
          if (response.success === true) {
            this.closeModal()
          } else {
            this.api_message = response.message
          }
        }.bind(this),
      })
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  renderModal() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        style={customStyles}
        contentLabel="CONSENT AGREEMENT"
        ariaHideApp={false}
      >
        <div className="modal_box">
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={this.closeModal}
          ></i>

          <h2 className="modal_title">CONSENT AGREEMENT</h2>
          <div className="modal_body" id="consent_items">
            <div className="s-checkbox1 text-left">
              <Checkbox name="consent[]" value="1" />
              &nbsp;&nbsp;I am aware of the medical and psychological risks
              associated with egg donation.
            </div>

            <div className="s-checkbox1 text-left">
              <Checkbox name="consent[]" value="2" />
              &nbsp;&nbsp;I understand that Egg Donation Inc. will display my
              profile (without my last name) and any photographs submitted by me
              on their website and in any other medium they determine
              appropriate.
            </div>

            <div className="s-checkbox1 text-left">
              <Checkbox name="consent[]" value="3" />
              &nbsp;&nbsp;I understand that Egg Donation Inc., potential
              Recipient Couples and other professionals will rely upon the
              information contained in my Application and I agree to answer each
              question completely and truthfully.
            </div>

            <div className="s-checkbox1 text-left">
              <Checkbox name="consent[]" value="4" />
              &nbsp;&nbsp;I understand that Egg Donation, Inc. does not and will
              not provide information or advice on the legal, medical, genetic
              or psychological risks of egg donation. I understand that I am
              solely responsible to obtain professional advice on the risks
              involved in the egg donation process.
            </div>

            <div className="s-checkbox1 text-left">
              <Checkbox name="consent[]" value="5" />
              &nbsp;&nbsp;I agree to release Egg Donation, Inc. and hold it
              harmless from any and all responsibility or liability with respect
              to any and all risks associated with the legal, medical, genetic,
              and/or psychological aspects of the egg donation process.
            </div>
          </div>
          <div className="modal_btns">
            <button
              className="btn btn-white btn-prompt-cancel"
              onClick={this.declineAgreement}
            >
              DECLINE
            </button>
            <button
              className="btn btn-green btn-prompt-accept"
              onClick={this.acceptAgreement}
            >
              ACCEPT
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  render_loading() {
    return (
      <div>
        <header></header>
        <div id="loading"></div>
      </div>
    )
  }

  render() {
    const { load_data } = this.state

    return (
      <div>
        <Header></Header>
        <ReactNotification ref={this.notificationDOMRef} />
        {load_data === 0 ? <div id="loading"></div> : ''}

        {this.render_content()}
      </div>
    )
  }
}

export default TDonor_Application
